@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap");

@font-face {
  font-family: "Outfit";
  src: url("../font/Outfit-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Outfit" !important;
}
/* animation start */
@keyframes slide-in {
  0% {
    transform: translateX(-400%);
  }

  100% {
    transform: translateX(400%);
  }
}

.react-slideshow-container .default-nav{
  margin-right: -4px !important;
}

@font-face {
  font-family: "Outfit";
  src: url("../font/Outfit-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Outfit" !important;
}
/* .service_naw_card p, .about__area-right-title p, .industrial-body p, .services-icons p, .why-choose-us .trending-technology  p{
  text-align: justify;
} */
.circle-line {
  animation: slide-in 2000ms ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  animation-direction: alternate-reverse;
}
.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  perspective: 1000px;
  visibility: hidden;
  /* transition: 0.4s all ease-in-out;
  transform: translateY(50px); */
  /* visibility: hidden; */
  /* box-shadow: -7px -5px 16px 8px rgb(76 76 76 / 15%); */
}
.dropdown {
  list-style-type: none;
}
.dropdown:hover .dropdown_menu--animated {
  visibility: visible;
  /* transform: translateY(10px);
  transition: 0.4s all ease-in-out; */
}

.dropdown .dropdown_menu--animated:active {
  visibility: visible;
}

.dropdown_menu-6 {
  border-top: 2px solid #ff5722;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  background: #ffffff;
  width: 245px;
  padding-left: 0;
  z-index: 999;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.banner-btn-body {
  display: flex;
}
.banner-btn-body a {
  display: block;
  width: 200px;
  line-height: 40px;
  font-size: 18px;
  text-decoration: none;
  color: #333;
  border: 1px solid #333;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all 0.35s;
  margin-right: 20px;
  font-weight: 600;
  border-radius: 5px;
}

.banner-btn-body a span {
  position: relative;
  z-index: 2;
}

.banner-btn-body a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #ec2229;
  transition: all 0.35s;
}

.banner-btn-body a:hover {
  color: #fff;
}

.banner-btn-body a:hover:after {
  width: 100%;
}

/* animation end */
.location-img {
  width: 6%;
}
.location-div {
  cursor: pointer;
}
.location-text {
  margin-right: 10px;
}
.service-block-one p {
  text-align: center;
}
.dialog-scroll {
  overflow-y: scroll;
}

/* header start */
.navitemlink {
  display: flex;
  margin-bottom: 0;
  align-items: center;
}
.navitemlink .navitem {
  position: relative;
  padding: 0 10px;
  font-size: 20px;
  text-decoration: none;
  /* color: #ea3e4d; */
  color: #101010;
  margin-left: 35px;
  font-weight: 500;
}
.navitemlink .navitem .dropdown_menu a {
  font-size: 18px;
  color: #f37325;
  margin: 0;
  display: block;
  padding: 5px 10px;
}
/* header end  */
.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}
.MuiAppBar-positionStatic {
  position: fixed !important;
}
.makeStyles-navlinks-1 {
  margin-bottom: 0;
}
.responsive-header {
  display: none;
}
.MuiToolbar-gutters {
  padding: 0 !important;
  display: flex;
  align-items: center !important;
  justify-content: space-between;
}
.headerlogo {
  /* height: 107px; */
  z-index: 99;
  width: 25%;
  text-align: right;
}
.headerlogo img {
  width: 100%;
  max-width: 220px;
}
.makeStyles-link-3 {
  margin-left: 35px !important;
}
.navbar-header p {
  margin: 0;
  font-size: 14px;
}
.navbar-header .phone,
.navbar-header .email,
.navbar-header .skype {
  align-items: center;
  font-weight: bold;
  margin: 0 10px;
}
.navbar-header .phone img,
.navbar-header .email img,
.navbar-header .skype img {
  padding: 0 5px;
  width: 25px;
}
.navbar-expand-lg .navbar-collapse {
  /* background-color: #f37325; */
  padding: 10px 0;
}

.navlinks {
  align-items: center;
}
.top-header .fa {
  font-size: 10px;
}
.top-header .phone a {
  color: #000;
  text-decoration: none;
}
.top-header .email .fa,
.top-header .email p,
.top-header .email a,
.top-header .skype .fa,
.top-header .skype p,
.top-header .skype a {
  color: #9d4714;
  text-decoration: none;
}
.navbar-header .link .nav-link {
  color: #fff;
  position: relative;
  font-size: 12px;
}
.navbar-header .link .nav-link.active {
  background-color: #fff;
  color: #ec2229;
  border-radius: 5px;
  padding: 5px 10px;
}
.navbar-header .link .nav-link:hover:after {
  width: 30px;
  height: 15px;
  content: "";
  left: 10px;
  position: absolute;
  bottom: -15px;
  background-color: #f37325;
  clip-path: polygon(7% 0, 61% 93%, 112% -12%);
}
.navbar-header .link {
  padding: 5px 15px;
  text-align: center;
  font-weight: bold;
}
.navbar-brand {
  /*clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);*/
  position: absolute;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -150px;
}
.navbar-brand img {
  width: 76%;
}
.navbar-body {
  display: flex;
  width: 100%;
}
.navbar-header {
  width: 100%;
}
.top-header {
  padding: 5px;
}
.navbar {
  padding: 0;
  margin-left: -200px;
  width: 80%;
}
.navbar-expand-lg .navlinks {
  padding-left: 135px;
}
.navitemlink .navitem .dropdown_menu a:hover {
  background: #f37325;
  color: #fff;
}
/*------------------------------banner start----------------------------*/
.mobile-view {
  display: none;
}
.grid.form-row.gallery.text-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
p {
  color: #4e4e4e;
  font-size: 21px;
  line-height: 35px;
}
.icons-text p {
  font-size: 19px;
}
.icon-text h5 {
  font-size: 23px;
}
.we-are-expert h4 {
  font-size: 21px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
.banner-section {
  padding-top: 100px;
}
.banner-section h1 {
  font-size: 54px;
  font-weight: 400;
}
.banner-section .home-banner-sub-title {
  padding: 15px 0 15px 10px;
}
.sub-heading {
  margin: 10px auto 0px;
  font-size: 19px;
  max-width: 940px;
  width: 100%;
}
.banner-section .banner-col.text .banner-col-body {
  width: 100%;
  position: absolute;
  right: -240px;
  bottom: 130px;
}
.banner-section .banner-col.text {
  width: 26%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.banner-section .banner-col.img {
  width: 74%;
}
.banner-section .banner-col.img img {
  width: 100%;
}
.ach_img .img-responsive {
  width: 100%;
}
.we-offer-img img {
  width: 100%;
}
.underline {
  width: 250px;
  height: 8px;
  background: linear-gradient(135deg, #ff3e57, #ff9d62);
  position: relative;
  margin: 15px auto 25px auto;
  border-radius: 5px;
}
.get-a-quote {
  padding: 8px 18px;
  font-size: 20px;
  text-decoration: none;
  color: #fff;
  margin-left: 35px;
  background-color: #ed594e;
  /* color: #ec282f; */
  border-radius: 40px;
  transition: 0.4s all ease-in-out;
}

/* .navitemlink .navitem:hover::after,
.navitemlink .navitem.active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -23px;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 15px;
  border-color: #f37325 transparent transparent transparent;
  z-index: 99;
} */
.navitem.dropdown::after,
.navitem.dropdown .navitem::after {
  display: none;
}
.circle-line {
  width: 25px;
  height: 25px;
  border-radius: 20px;
  margin-bottom: 0;
  background-color: #fff;
  border: 5px solid #ff3f57;
  position: absolute;
  top: -9px;
  left: 112px;
}
/*--------------------------------banner end-------------------------*/
/*-----------------------------what-we-provide start------------------*/
/* .what-we-provide-body {
  padding: 50px 0;
} */
/* .what-we-provide {
  padding: 50px 0;
} */
.what-we-provide {
  padding: 10px 0;
}
.title-clr {
  color: #f37325;
  font-size: 22px;
}
.all-title {
  font-size: 36px;
  /* margin-bottom: 26px; */
  margin-bottom: 12px;
}
.what-we-provide-col {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #fbe4d4;
  margin: 10px 20px;
  text-align: center;
  background-color: #fafafa;
  height: 390px;
  transition: transform 1.2s;
  transform-style: preserve-3d;
}
/* .what-we-provide-col:hover{
	transform: rotateY(180deg);
}
.what-we-provide-col:hover p{
	transform: rotateY(180deg);
	transition-delay: 0.3s;
} */
/* .what-we-provide-col:hover,
.contact-box-body:hover {
  background: linear-gradient(135deg, #ff3e57, #ff9d62);
  color: #fff;
} */
.what-we-provide-body .what-we-provide-col:hover .read-more {
  background-color: #fff;
  /* transform: rotateY(180deg);
	transition-delay: 0.3s; */
}
/* .what-we-provide-col:hover p,
.contact-box-body:hover p {
  color: #fff;
} */
.what-we-provide-col p {
  line-height: 30px;
  font-size: 18px;
}
.what-we-provide-body .what-we-provide-col .read-more {
  color: #f64c67;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  background: #fbe4d4;
  padding: 20px;
  border-radius: 50%;
}
/* .what-we-provide-col:hover h5{
	transform: rotateY(180deg);
	transition-delay: 0.3s;
} */
.what-we-provide-col h5 {
  margin: 0;
  padding: 10px 0;
  font-weight: bold;
  font-size: 23px;
  margin: 10px;
}
.what-we-provide .title-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.hovericons {
  display: none;
}
/* .what-we-provide-body .what-we-provide-col:hover img{
	transform: rotateY(180deg);
	transition: transform 1.2s;
} */
.what-we-provide-body .what-we-provide-col:hover .non-hover {
  display: none;
}
.what-we-provide-body .what-we-provide-col:hover .hovericons {
  display: block;
}
/*------------------------------what-we-provide end---------------------*/
/*---------------------------------aboutUs start------------------------*/
.aboutUs {
  background-color: #ecf4fb;
  padding: 50px;
}
.payment-section {
  align-items: center;
}
.aboutUs-body .about-img img {
  width: 100%;
}
.payment-img {
  width: 65%;
}
/*.aboutUs .aboutUs-body{
	padding: 0 100px;
}*/
.about-us-col {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-us-col .icon-text h4 {
  margin: 0;
  padding: 0 10px;
  font-size: 20px;
  color: #fff;
}
.about-us-col .icon-text {
  margin-top: -30px;
  padding: 20px;
  width: 230px;
  background: #f37325;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
}
.about-us-col .icon-text p {
  position: absolute;
  width: 30px;
  background-color: blue;
  border-radius: 50%;
  bottom: -15px;
  padding: 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.about-us-col .icon-text .fa {
  color: #fff;
}
.about-us-col .icon-text .fa.fa-info-circle {
  color: #fff;
  font-size: 25px;
}
.css-1xjww78, .css-1acvgar-MuiTypography-root{
  display: none !important;
}
/*-----------------------about us section end------------------------*/
/*-----------------------working-process-section start-----------------*/
.working-process-section {
  background-color: #020236;
  color: #fff;
  text-align: center;
  padding: 100px 50px;
  clip-path: polygon(50% 0%, -225% 100%, 300% 100%);
}
.working-dotted-body {
  padding: 50px;
}
.working-process-section .circle-line {
  padding: 0 !important;
}
.working-process-section p {
  color: #fff;
  padding: 5px 0;
}
.working-process-section .accordion-body p {
  text-align: start;
}
.working-process-section .working-title {
  font-size: 17px;
  min-height: 40px;
}
/*-----------------------working-process-section end-----------------*/
/*-----------------------technology-section start-----------------*/

.technology-section {
  padding: 100px 50px 50px 50px;
  text-align: center;
}
.technology-row {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.technology-img {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.technology-img h5 {
  font-size: 15px;
  padding: 10px 0;
}
/*-----------------------technology-section end-----------------*/
/*-----------------------testimonials-section start-----------------*/
.testimonial-section {
  padding: 50px 0;
  text-align: center;
}
.testimonial-section .stars {
  color: #ffc107;
  padding: 20px 0;
}
.testimonial-section .content p {
  font-style: italic;
  font-size: 18px;
}
.testimonial-section .testimonial-col {
  background-color: #f5f8fa;
  padding: 20px 26px;
  margin: 10px;
  /* height: 560px; */
}
.testimonial-section .profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
}
.testimonial-section .profile img {
  width: 100%;
}
.testimonial-section .profile-name p {
  font-size: 12px;
}
.testimonial-section .profile-name {
  padding: 20px 0;
}
/*-----------------------testimonials-section end-----------------*/
/*---------------------our-clients-section start------------------*/
.our-clients-section {
  padding: 50px 0;
  text-align: center;
}
.sponser-img {
  padding: 10px;
  outline: none;
}
.sponser-img img {
  width: 90%;
  margin: 0 auto;
  outline: none;
}
/*-----------------------our-clients-section end------------------*/
/*-------------------------footer-section start--------------------*/
.footer-section {
  background-color: #040819;
  color: #fff;
}
.footer-section .footer-body {
  /* padding: 50px; */
  padding: 20px;
  padding-bottom: 0;
}
.footer-section .footer-list {
  display: flex;
  flex-direction: column;
  /* margin-top: 40px; */
}
.copyright-section p {
  margin-bottom: 0;
  font-size: 14px;
  color: #333;
}
.copyright-section {
  /* background: #f05a23; */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-section .footer-list a {
  text-decoration: none;
  color: #333;
  line-height: 35px;
  text-transform: capitalize;
  font-size: 15px;
}
/* .footer-section .footer-list a:hover {
  text-decoration: underline;
} */
.footer-section .footer-col p {
  color: #fff;
}
.footer-section .footer-list input {
  font-size: 12px;
  padding: 5px;
}
.footer-section .footer-country {
  border: 1px solid #424242;
  text-align: center;
  padding: 5px;
}
.footer-section .footer-country p {
  margin: 0;
}
.footer-section .subscribe {
  background-color: #ed594e;
  margin: 10px 0;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}
.footer-section .footer-list .subscribe {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
}
.footer-section .footer-list .subscribe:hover {
  text-decoration: none;
  background-color: #e93a4d;
}
.navbar-expand-lg .navlinks {
  z-index: 9999;
}
.MuiPaper-elevation4 {
  box-shadow: 0px 10px 50px 0px rgba(62, 62, 62, 0.2) !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer-logo {
  padding-bottom: 30px;
}
.footer-col {
  padding: 0 30px;
}
.footer-col.one {
  padding: 0;
}
.banner_info_wrapper {
  margin-left: 26%;
}
.why-we-choose-right ul li {
  font-size: 19px;
  line-height: 180%;
  color: #808080;
}
.why-choose-us-title h2 {
  font-size: 36px;
}
.why-we-choose-right h2 {
  font-size: 23px;
}

.row.align-items-center.who-we {
  padding-bottom: 50px;
}
.desc-text {
  width: 522px;
}
/*--------------------------footer-section end---------------------*/

/*media query*/
@media only screen and (max-width: 2500px) and (min-width: 1921px) {
  .headerlogo {
    /* height: 109px; */
  }
}
@media only screen and (max-width: 1440px) {
  .banner-section h1 {
    font-size: 40px;
  }
  .banner-section {
    padding-top: 82px;
  }
  .makeStyles-link-3,
  .get-a-quote {
    font-size: 17px !important;
  }
  .banner-section .banner-col.text .banner-col-body {
    bottom: 55px;
    right: -195px;
  }
  .all-title {
    font-size: 44px;
  }
  .headerlogo {
    /* height: 87px; */
  }
  .navbar {
    width: 100%;
  }
  .navitemlink .navitem {
    font-size: 18px;
    margin-left: 20px;
  }
  .img-responsive {
    width: 100%;
  }
  .banner_info_wrapper {
    margin-left: 14%;
  }
  .testimonial-section .testimonial-carousel {
    max-width: 970px !important;
  }
}
@media screen and (max-width: 1366px) {
  .hero_banner_image .img-responsive {
    width: 100%;
  }
  .testimonial-section .testimonial-carousel {
    max-width: 920px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .banner-btn-body a {
    font-size: 16px;
  }
  .testimonial-section .content p {
    font-size: 15px;
  }
  .sub-heading {
    font-size: 16px;
  }
  .all-title {
    font-size: 28px;
  }
  .navbar {
    margin-left: -75px;
    width: 100%;
  }
  .banner-section .banner-col.text .banner-col-body {
    bottom: 30px;
  }
  .working-dotted-body {
    padding: 20px 55px;
  }
  p {
    font-size: 16px;
  }
  .owlcarousel-slider .owl-nav {
    position: absolute !important;
    right: 40% !important;
    top: 78% !important;
  }
}
@media only screen and (max-width: 1240px) {
  .owlcarousel-slider .owl-nav {
    position: absolute !important;
    right: 40% !important;
    top: 90% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .banner-body {
    flex-direction: column;
  }
  .banner-btn-body {
    justify-content: center;
  }
  .banner-section {
    padding-top: 100px;
  }
  .MuiButtonBase-root {
    padding: 20px !important;
    margin: 0 20px !important;
    border: 10px solid #d7242c !important;
  }
  .MuiToolbar-gutters {
    background: #040819d1;
    position: fixed !important;
    overflow: hidden;
    width: 100%;
    align-items: center !important;
    justify-content: space-between;
  }
  .MuiListItem-gutters {
    padding: 10px 0 !important;
  }
  .MuiListItemText-root a {
    color: #fff;
    font-size: 18px;
    text-decoration: none;
  }
  .MuiDrawer-paperAnchorLeft {
    right: 60% !important;
    padding: 20px;
    /* justify-content: center; */
    background-color: #000 !important;
  }
  .mobile-responsive .dropdown-toggle.nav-link {
    font-size: 24px;
  }
  .mobile-responsive .dropdown-menu.show {
    background-color: #ff5722;
    width: 260px;
  }
  .mobile-responsive .MuiListItemText-root a {
    padding: 10px;
  }
  .headerlogo img {
    width: 85%;
  }
  .banner-section .banner-col.text {
    width: 100%;
    order: 2;
  }
  .banner-section .banner-col.text .banner-col-body {
    position: initial;
    text-align: center;
    padding: 50px 10px;
  }
  .banner-section .banner-col.img {
    width: 100%;
  }
  .sub-heading {
    font-size: 14px;
  }
  .headerlogo {
    /* height: 98px; */
    padding: 5px 20px;
  }
  p {
    font-size: 13px;
  }
  .testimonial-section .testimonial-col {
    padding: 20px 10px;
  }
  .testimonial-section .content p {
    font-size: 13px;
  }
  .what-we-provide-body .what-we-provide-col {
    height: 400px;
    padding: 10px;
    margin: 10px 10px;
  }
  .responsive-header {
    display: block;
    padding: 15px;
    width: 45%;
  }
  .main-header {
    display: none;
  }
  .all-title {
    font-size: 28px;
  }
  .what-we-provide-body .what-we-provide-col p {
    line-height: 25px;
  }
  .working-dotted-body .dotted-img {
    width: 65px;
    height: 65px;
  }
  .working-dotted-body .dotted-img img {
    width: 100%;
  }
  .MuiPaper-elevation16 .MuiSvgIcon-root {
    color: #fff;
    font-size: 35px;
  }
  .MuiListItem-button {
    border: none !important;
    margin-left: 0 !important;
  }
  .MuiButtonBase-root .MuiListItemText-root {
    /* flex: 0 1 auto !important; */
  }
}

@media only screen and (max-width: 991px) {
  .banner_info_wrapper {
    margin-top: 30px;
  }
  .hero_banner_image .img-responsive {
    width: 65%;
  }
  .main_header_wrapper {
    position: relative;
    margin-top: 60px;
    margin-bottom: 25px;
  }
  .hero_banner_image {
    text-align: center;
  }

  .row.align-items-center.who-we .col-md-6 {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .sub-heading {
    width: 100%;
  }
  .space {
    margin: 0 10px;
  }
  .working-process-row .justify-content-between {
    justify-content: center !important;
  }
  .working-dotted-body .dotted-img {
    width: 40px;
    height: 40px;
  }
  .working-dotted-body p img {
    width: 25px;
  }
  .working-dotted-body {
    padding: 0px;
  }
  .working-process-section .working-title {
    min-height: 10px;
    margin-bottom: 0;
  }
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
  .mobile-view .dotted-img {
    padding: 10px;
  }
  p {
    line-height: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .headerlogo {
    width: auto;
  }
  .testimonial-section .testimonial-col {
    height: 450px;
  }
  .technology-row .col-sm-3 {
    width: 50%;
  }
  .space {
    margin: 0 0;
  }
  .banner-btn-body a {
    width: 145px;
    line-height: 30px;
    font-size: 13px;
  }
  .what-we-provide {
    padding: 0;
  }
  .what-we-provide-body .what-we-provide-col img {
    height: 100px;
  }
  .headerlogo {
    /* height: 65px; */
  }
  .headerlogo img {
    width: 55%;
  }
  .MuiButtonBase-root {
    padding: 10px !important;
  }
  .working-process-section {
    clip-path: none;
  }
  .banner-section h1 {
    font-size: 34px;
  }
  .MuiDrawer-paperAnchorLeft {
    right: 40% !important;
  }
  .MuiListItem-gutters {
    padding: 10px 0 !important;
  }
  .MuiListItemText-root a {
    font-size: 16px;
  }
  .navbar-brand {
    width: 83%;
    padding-top: 10px;
  }
  .footer-section .footer-sec-col {
    padding: 10px 0;
  }
  .about-us-col {
    margin: 20px 0;
  }
  .sub-heading {
    width: 100%;
    padding: 10px;
  }
  .about-us-col .icon-text .fa.fa-info-circle,
  .about-us-col .icon-text h4 {
    font-size: 15px;
  }
  .navbar-header .top-header {
    flex-direction: column;
  }
  .banner-section {
    padding-top: 64px;
  }
  .banner-body {
    flex-direction: column;
  }
  .banner-section .banner-col.img {
    width: 100%;
  }
  .title-clr {
    font-size: 14px;
  }
  .banner-section .banner-col.text {
    width: 100%;
    justify-content: center;
    padding: 20px 0;
  }
  .footer-section .footer-list {
    align-items: flex-start;
    justify-content: center;
  }
  .footer-section .subscribe {
    width: 100%;
  }
  .footer-section .follow-us {
    text-align: left;
  }
  .footer-section .follow-us .social-icon {
    justify-content: left;
  }
  .working-dotted-body {
    flex-direction: column;
    align-items: center;
  }
  .working-dotted-body .dotted-img {
    width: 100px;
    height: 110px;
  }
  .technology-section,
  .working-process-section {
    padding: 50px 0;
  }
  .working-dotted-body {
    padding: 20px;
  }
  .our-clients-section .sponser-img {
    margin: 10px 15px;
  }
  .title-clr {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 320px) {
  .MuiListItem-gutters {
    padding: 0 5px !important;
  }
  .MuiListItemText-root a {
    font-size: 13px;
  }
}

/* ----------------------------------------------------home page end------------------------------------------------- */

/*------------------------------------------------- about us page start----------------------------------------------- */
.about-us-section {
  background-image: url(../images/abutbanner-1.jpg);
}

section.about-us-section.career-section {
  background-image: url(../images/careerbnr-1.png);
  background-position: 0px 59px;
}

.contactus-section {
  background-image: url(../images/contactbanner.jpg);
  height: 320px;
}
.appservices-section {
  background-image: url(../images/servicebnr-1.png);
  height: 320px;
}

.about-us-section,
.portfolio-section,
.appservices-section {
  align-items: center;
  text-align: center;
  padding-top: 100px;
  justify-content: center;
  position: relative;
}
.portfolio-section,
.about-us-section {
  height: 320px;
  background-repeat: no-repeat;
}
.contactus-section {
  align-items: center;
  justify-content: center;
}

.appservices-section:after,
.portfolio-section:after {
  content: "";
  background: #000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.4;
}
.appservices-body,
.about-us-body {
  position: relative;
  z-index: 2;
}

.aboutus-text,
.aboutus-text p,
.portfolio-text,
.portfolio-text p,
.contactus-text,
.contactus-text p,
.appservices-text,
.appservices-text p {
  color: #fff;
}
.aboutus-text span,
.portfolio-text span,
.contactus-text span,
.appservices-text span {
  color: #f37325;
}
.industrial-body .underline {
  margin: 0;
}
.about-us-right-img img,
.why-choose-us-left img {
  width: 90%;
}
.about-main {
  background-color: #fff;
}
.industrial-work {
  padding: 50px 0;
}
.industrial-work .text {
  /* padding: 20px 0; */
  padding: 0;
}
.why-choose-us-title {
  text-align: center;
}
.right-center {
  margin-left: 65px;
}
.right-about-img img {
  width: 70%;
}
.why-we-choose-right .icon-img {
  /* padding: 5px 30px; */
  width: 13%;
  margin-right: 25px;
}
.why-we-choose-right .icon-img img {
  width: 100%;
}
.vision-section {
  padding: 50px 0;
}
.vision-section .vision-box {
  height: 400px;
  background: #ecf5fd;
  border-radius: 20px 20px 200px 20px;
  padding: 50px;
}
.vision-section .vision-box h5 {
  font-size: 40px;
  position: relative;
}
.vision-section .vision-box h5:after {
  content: "";
  width: 80px;
  height: 5px;
  position: absolute;
  background-color: #ffa30c;
  bottom: -6px;
  left: 0;
  border-radius: 10px;
}
.vision-section .vision-box p {
  font-size: 24px;
  padding: 30px 0px;
}
.vision-section .vision-col {
  position: relative;
  padding: 20px;
}
.vision-section .vision-icon img {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.vision-section .vision-box:hover h5,
.vision-section .vision-box:hover p {
  color: #fff;
}
.vision-section .vision-box:hover {
  background: linear-gradient(130deg, #ff3856, #ff9d62);
}
/*---------------------------------- portfolio-section start------------------------------------ */
.portfolio-section {
  background-image: url(../images/portfolionew.jpg);
  background-position: 0px 59px;
}
/*---------------------------------- portfolio-section end------------------------------------ */

/*---------------------------------- contactus-section start------------------------------------ */

.contact-box-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #fbe4d4;
  text-align: center;
  background-color: #fafafa;
  width: 100%;
  height: 300px;
  justify-content: center;
  margin-top: 20px;
}
.form-control {
  background-color: #f4f4f4;
  /* border: none; */
  padding: 10px;
}
.submit-form-body {
  text-align: center;
}
.form-section {
  padding: 50px;
  margin: 50px;
  border: 1px solid #fbe4d4;
  border-radius: 10px;
}
.contact-box-body h5 {
  padding-top: 20px;
  font-size: 21px;
}
.contact-box-body p {
  margin-bottom: 0;
}
.location-here {
  padding: 50px 0;
}
.contact-form-btn {
  justify-content: center;
}
.contact-form-btn button {
  width: 300px;
  border-radius: 35px;
  background: linear-gradient(135deg, #ff3e57, #ff9d62);
  border: none;
}
/*=========================================== contact us page end =================================================*/

/*====================================== app development services start============================================= */
.sub-para {
  /* padding: 20px 0; */
  padding: 10px 0;
  margin-bottom: 0;
  color: #000;
}
.space-container .space {
  height: 50%;
}
.maparea {
  position: relative;
  height: 500px;
}
.services-icons .icon-box img {
  width: 100%;
}
.services-icons {
  padding: 25px 0 0 0;
}
.services-icons .icon-box {
  width: 50px;
  margin-right: 25px;
  /* padding: 20px; */
}
.services-icons .icons-col {
  min-height: 145px;
}
.services-icons .icons-text {
  width: 85%;
}
.icons-text h5 {
  font-size: 23px;
}
.trending-technology,
.app-development-process {
  padding: 0px 0;
  margin-top: 16px;
}
.dropdown-toggle.nav-link {
  padding: 0;
  color: #fff;
  display: flex;
  align-items: center;
}
.dropdown .makeStyles-link-3 {
  color: #f37325;
  display: flex;
  margin: 0 !important;
  text-align: center;
  justify-content: center;
  padding: 5px;
}
.makeStyles-link-3:hover {
  color: yellow;
  background: #f37325;
}
.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 230px;
}
/*====================================== app development services end============================================= */
/*====================================== web development services start============================================= */

.web-development-service .text {
  padding: 0;
}
.expert-img img {
  width: 100%;
  padding: 20px;
}
.we-are-expert .expert-list {
  display: flex;
  flex-wrap: wrap;
  line-height: 45px;
  margin: 0;
}
.we-are-expert .expert-list li {
  width: 50%;
  font-size: 18px;
}

/*====================================== web development services end============================================= */

/*======================================career pages start============================================= */
.career-icons-img:hover {
  background: linear-gradient(130deg, #ff3856, #ff9d62);
  color: #fff;
}
.career-join-osiyatech {
  padding: 0px 0 50px 0;
}
.career-icons-img:hover .non-hover {
  display: none;
}
.career-icons-img:hover .on-hover {
  display: block;
  margin: 25px auto;
}
.career-icons-img .on-hover {
  display: none;
}
.career-icons-img {
  text-align: center;
  border-radius: 10px;
  /* padding: 10px; */
  border: 1px solid #fbe4d4;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 164px;
}
.career-icons-img h6 {
  font-size: 14px;
}
.career-icons-img img {
  margin: 25px;
  max-width: 60px;
}
.career-icons-img h6 {
  padding: 10px 0;
}
.accordion-item .what-responsiilities {
  margin-bottom: 20px;
}
.what-responsiilities p {
  margin-bottom: 0;
  padding-left: 10px;
  text-align: start;
}
.what-responsiilities h4 {
  text-align: left;
  font-size: 28px;
}
.what-responsiilities .list-text {
  padding: 0px 0 0px 20px;
}
.what-responsiilities .list-text img {
  padding-top: 15px;
}
.other-roles .btn {
  background: linear-gradient(130deg, #ff3856, #ff9d62);
  border: none;
}
.other-roles .btn:focus,
.modal-apply:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.submit-button {
  text-align: left;
}
.accordion-button {
  font-size: 28px !important;
  color: #fff !important;
}
.accordion-item,
.accordion-button.collapsed,
.accordion-button:not(.collapsed) {
  background: #051242;
  background-color: #051242;
}
.current-opening {
  background-color: #051242;
  clip-path: polygon(50% 0%, -1000% 100%, 1000% 100%);
}
.accordion-button::after {
  background-image: url(../images/minus.png) !important;
}
.accordion-button.collapsed::after {
  background-image: url(../images/plus.png) !important;
}
.apply-here {
  padding: 50px 0;
}
.apply-submit-btn {
  width: 300px;
  background: linear-gradient(130deg, #ff3856, #ff9d62);
  border: none;
}
.middle-center {
  margin-left: 150px;
}
.exciting-title {
  font-size: 23px;
  padding-bottom: 10px;
}
/* .accordion-button:not(.collapsed)::after{.career-icons-img {

	transform: none;
} */

/* -----------------------------------isotop gallary--------------------------------- */
.filtering {
  margin-bottom: 40px;
}
.filtering span {
  border-bottom: 2px solid transparent;
  color: #282b2d;
  cursor: pointer;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-right: 50px;
  display: inline-block;
  margin-bottom: 5px;
}
.filtering span:last-child {
  margin: 0;
}
.filtering .active {
  border-color: #f37325;
  color: #f37325;
}
.portfolio-wrapper {
  position: relative;
  overflow: hidden;
}
.portfolio-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: all 500ms ease;
}
.portfolio-wrapper .portfolio-image img {
  transform: scale(1.2);
  will-change: transform;
  transition: all 0.5s ease;
}
.portfolio-wrapper:hover .portfolio-image img {
  transform: none;
}
.portfolio-overlay:before {
  position: absolute;
  display: inline-block;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  border: 1px solid rgba(0, 0, 0, 0.36);
  content: "";
  opacity: 0;
  transition: all 0.5s ease;
  transform: scale(0.85);
}
.portfolio-overlay .portfolio-content {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  padding: 0 35px;
}
.portfolio-content h4 {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.portfolio-content p {
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.portfolio-content > a {
  line-height: 42px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #000;
  display: inline-block;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 800;
}
.portfolio-wrapper:hover .portfolio-overlay {
  background-color: rgba(204, 222, 2, 0.85);
}
.portfolio-wrapper:hover .portfolio-overlay:before {
  opacity: 1;
  visibility: visible;
  transform: none;
}
.portfolio-wrapper:hover .portfolio-overlay .portfolio-content {
  transform: translateY(50%);
  transition: transform 0.5s ease;
  opacity: 1;
}
.grid .grid-item {
  position: relative;
  overflow: hidden;
}
.grid .grid-item .portfolio-wrapper {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
.grid .grid-item .portfolio-overlay {
  position: absolute;
  left: 0px;
  top: 0;
  height: 100%;
  width: 100%;
  transition: all 500ms ease;
}
.grid .grid-item .portfolio-wrapper .portfolio-image img {
  transform: none;
  will-change: transform;
  transition: none;
  width: 100%;
}
.grid .grid-item .portfolio-wrapper:hover .portfolio-image img {
  transform: none;
}
.grid .grid-item .portfolio-overlay:before {
  position: absolute;
  display: inline-block;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  border: 1px solid rgba(0, 0, 0, 0.36);
  content: "";
  opacity: 0;
  transition: all 0.5s ease;
  transform: scale(0.85);
}
.grid .grid-item .portfolio-overlay .portfolio-content {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
}
.grid .grid-item .portfolio-content h4 {
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 20px;
  margin-bottom: 10px;
}
.grid .grid-item .portfolio-content p {
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.grid .grid-item .portfolio-content a {
  line-height: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #000;
  display: inline-block;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 800;
}
.grid .grid-item .portfolio-wrapper:hover .portfolio-overlay {
  background-color: rgba(204, 222, 2, 0.85);
}
.grid .grid-item .portfolio-wrapper:hover .portfolio-overlay:before {
  opacity: 1;
  visibility: visible;
  transform: none;
}
.grid
  .grid-item
  .portfolio-wrapper:hover
  .portfolio-overlay
  .portfolio-content {
  transform: translateY(50%);
  transition: transform 0.5s ease;
  opacity: 1;
}
/* website optimization start */
.core-web-vital-section .col-body li {
  background-color: #cdebd3;
  padding: 10px 20px;
  border-radius: 15px;
  list-style: none;
  margin: 10px 0;
  width: 350px;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.core-web-vital-section .col-body .col-two li {
  background-color: #e9eaee;
}
.core-web-vital-section .col-body .col-one {
  border: 5px solid #5a916a;
  padding: 10px;
  border-radius: 35px;
  justify-content: space-evenly;
}
.core-web-vital-section .col-body li p {
  margin-bottom: 0;
  font-size: 14px;
}
.core-web-vital-section .col-body li h5 {
  font-size: 25px;
}
.core-web-vital-section .col-two ul {
  padding-left: 170px;
}
.vital-section h4 {
  font-size: 30px;
  color: #5a916a;
}
.vital-section {
  width: 100px;
}
.vital-three-section {
  position: relative;
}
.vital-three-section .MuiSvgIcon-root {
  position: absolute;
  right: -170px;
  top: 115px;
  width: 5em;
  height: 3em;
}
.web-vital-body .col-two li {
  position: relative;
}
.web-vital-body .col-two li .MuiSvgIcon-root {
  position: absolute;
  right: -170px;
  width: 5em;
  height: 3em;
}
.core-web-vital-section .signal-section h4 {
  font-size: 40px;
}
.core-web-vital-section .signal-section {
  width: 455px;
  margin: 0 auto;
  color: #fff;
  height: 100%;
  background: #4385f5;
  padding: 0 75px;
  border-radius: 15px;
  display: flex;
  align-items: center;
}
.important-factor,
.core-web-vital-section {
  padding: 50px 0;
}
.important-factor .loading-list li {
  width: 45%;
  padding: 10px 0;
  font-size: 18px;
}
.loading-list li span {
  font-weight: bold;
  font-size: 20px;
}
.important-factor .loading-list {
  flex-wrap: wrap;
  justify-content: space-between;
}
.important-factor .factors p {
  font-size: 22px;
}

/* ------------------------------------------------------------------------------------------ */
.itsolution .icons-col .icon-box {
  width: 3%;
}
/* slick carousel slider  */
.slick-next:before,
.slick-prev:before {
  color: #f44336;
  font-size: 40px;
}
.slick-slider {
  padding: 20px;
}

a.get-a-quote:hover {
  box-shadow: 2px 4px 13px 0px rgb(199 199 199);
  color: #ffffff;
  transition: 0.4s all ease-in-out;
}
.mobile_dtroptoggle {
  padding: 10px 0px !important;
}
.testimonial-section .slick-dots {
  bottom: 0px;
}

/* ---------- new home page css start ------------- */
/*pallavi css*/
/* weOffer css starts from here */
.section-padding {
  /* padding: 120px 0px; */
  padding: 12px 0px;
}
.about__area-left {
  margin-right: 95px;
  margin-bottom: 60px;
}
.about__area-left-image {
  position: relative;
}
.about__area-left-image-content {
  position: absolute;
  right: -30px;
  bottom: -60px;
  background: #f3555a;
  padding: 20px 20px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  max-width: 300px;
  width: 100%;
}
.about__area-left-image-content-icon {
  margin-bottom: 20px;
}
.about__area-left-image-content-icon img {
  max-width: 60px;
}
.about__area-left-image-content h2 {
  color: #fff;
  font-size: 60px;
  line-height: 70px;
  padding: 0;
  margin: 0;
  color: #343a40;
  font-weight: 700;
}
.about__area-left-image-content span {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}
.about__area-left {
  margin-right: 95px;
  margin-bottom: 60px;
}
/* .subtitle-two {
  display: block;
  font-size: 140px;
  line-height: 1;
  font-weight: 700;
  color: #343a40;
  opacity: 0.1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  margin: 0 0 -85px 0;
  position: relative;
  z-index: -1;
} */
.subtitle-one {
  display: block;
  position: relative;
  font-weight: 600;
  /* color: #e34115; */
  color: #000;
  margin-bottom: 6px;
  font-size: 36px;
}
.about__area-left-image-content h2 {
  color: #fff;
  font-size: 30px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  font-weight: 400;
}

.about__area-right-title p {
  max-width: 520px;
  /* font-size: 15px; */
  /* line-height: 28px; */
}
.about__area-right-list {
  margin-top: 35px;
}
.about__area-right-list h5 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 28px;
  padding: 0;
  margin: 0;
  color: #343a40;
  font-weight: 700;
  margin-bottom: 10px;
}
.about__area-right-list h5:last-child {
  margin: 0;
}
.about__area-right-bottom {
  display: flex;
  align-items: center;
  margin-top: 35px;
}
.about__area-right-bottom-item {
  margin-right: 40px;
}
.about__area-right-bottom-item-author {
  display: flex;
  align-items: center;
}
.about__area-right-bottom-item-author-image {
  margin-right: 20px;
}
.about__area-right-bottom-item-author-image img {
  max-width: 60px;
  border-radius: 50%;
}
.theme-btn1 {
  background: #f6b720;
  display: inline-flex;
  color: #fff;
  font-size: 16px;
  padding: 0 35px;
  text-align: center;
  font-weight: 700;
  z-index: 3;
  position: relative;
  transition: all 0.5s ease-out 0s;
  align-items: center;
  line-height: 60px;
  overflow: hidden;
  text-decoration: none;
}
.about__area-right-list h5 i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ededed;
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0 0 60px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.08);
  margin-right: 8px;
  text-align: center;
  border-radius: 50%;
  color: #e34115;
}

/*end css*/

.ost-hero_banner_text h1 {
  font-size: 2.6rem;
  color: #fff;
  font-weight: 300;
}
.banner_title_wrapper {
  font-weight: 500;
}
.ost-hero_banner_text p {
  color: #fff;
  margin-top: 24px;
  margin-bottom: 24px;
}
.banner_btn_section {
  /* margin-top: 30px; */
  margin-top: 15px;
}
.hero_banner_image {
  z-index: 9;
  position: relative;
}

.btn_started {
  background: #f3555a;
  padding: 12px 30px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 30px;
  font-size: 20px;
  margin-right: 10px;
  transition: 0.4s all ease-in-out;
  text-decoration: none;
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
}

.btn_started:hover {
  color: #fff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  /* transform: translateY(10px);
  transition: 0.4s all ease-in-out; */
  background: #ff9189;
}
.btn_find_services {
  border: 1px solid #fff;
  padding: 12px 30px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 30px;
  font-size: 20px;
  margin-right: 10px;
  transition: 0.4s all ease-in-out;
  text-decoration: none;
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
}
.btn_find_services:hover {
  border: 1px solid #fff;
  background-color: #fff;
  color: #ea3f4d;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  transform: translateY(10px);
  transition: 0.4s all ease-in-out;
}

.banner_info_wrapper {
  position: relative;
  z-index: 9;
}

.shape-circle-1 {
  position: absolute;
  width: 120px;
  height: 120px;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 50%;
  margin: 0;
  top: 25%;
  left: 16%;
  z-index: 1;
}

.animation-shape_shape-ring {
  position: absolute;
  top: 75%;
  left: 25%;
}

.animation--rotating-diagonal {
  -webkit-animation: rotating-diagonal 15s linear infinite alternate;
  animation: rotating-diagonal 15s linear infinite alternate;
}

@keyframes rotating-diagonal {
  0% {
    -webkit-transform: translate(-300px, 150px) rotate(0);
    transform: translate(-300px, 150px) rotate(0);
  }

  100% {
    -webkit-transform: translate(300px, -150px) rotate(180deg);
    transform: translate(300px, -150px) rotate(180deg);
  }
}
@-webkit-keyframes rotating-diagonal {
  0% {
    -webkit-transform: translate(-300px, 150px) rotate(0);
    transform: translate(-300px, 150px) rotate(0);
  }

  100% {
    -webkit-transform: translate(300px, -150px) rotate(180deg);
    transform: translate(300px, -150px) rotate(180deg);
  }
}

.animation-shape_shape-ring div {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 5px solid #ff6f61;
}

/*--------- animated shape 2 -----------*/

.animation-shape-shape-circle {
  position: absolute;
  top: 20%;
  left: 50%;
  z-index: 1;
}
.animation--clockwise {
  -webkit-animation: clockwise 30s linear infinite normal;
  animation: clockwise 30s linear infinite normal;
}

@-webkit-keyframes clockwise {
  0% {
    -webkit-transform: rotate(0) translate(-165px) rotate(0);
    transform: rotate(0) translate(-165px) rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg) translate(-165px) rotate(-360deg);
    transform: rotate(360deg) translate(-165px) rotate(-360deg);
  }
}

@keyframes clockwise {
  0% {
    -webkit-transform: rotate(0) translate(-165px) rotate(0);
    transform: rotate(0) translate(-165px) rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg) translate(-165px) rotate(-360deg);
    transform: rotate(360deg) translate(-165px) rotate(-360deg);
  }
}

.animation-shape-shape-circle div {
  width: 15px;
  height: 15px;
  background: rgba(237, 244, 248, 0.3);
  border-radius: 50%;
  margin: 0;
}
.main_header_wrapper {
  position: relative;
  /* margin-top: 40px; */
  margin-top: 90px;
  margin-bottom: 40px;
}
.pt-150 {
  padding-top: 55px;
}
.header_bg {
  background-image: url(../images/header_bg_img-01.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  min-height: 800px;
}
/*--- new css for footer design ---*/

.contact_card {
  display: flex;
  /* align-items: center; */
  background: #fff;
  box-shadow: 0px 10px 51px 0px rgb(69 69 69 / 16%);
  margin-bottom: 50px;
  padding: 20px;
}
.contact_card img {
  height: 50px;
  margin-right: 20px;
}
.contact_info_wrapper {
  background-image: url(../images/footer_tringle_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}
.contact_info_wrapper a {
  color: #000;
  text-decoration: none;
}
.contact_info_div {
  margin-top: -3px;
}
.location-card-section a {
  color: #4e4e4e;
  text-decoration: none;
}
.footer-section {
  background-color: #ebebeb;
  color: #333;
  position: relative;
}
.footer-section .footer-col p {
  color: #333;
  font-size: 16px;
}
.footer-section:after {
  content: "";
  background-image: url(../images/circle_footer_bg.png);
  position: absolute;
  right: 0;
  width: 200px;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: bottom right;
  height: 300px;
  background-size: contain;
  z-index: 1;
}
.contact_info_wrapper {
  position: relative;
  z-index: 9;
  margin-top: 20px;
}

.dropdown-6 {
  cursor: pointer;
}
.footer-list h6,
.follow-us h6 {
  position: relative;
  margin-bottom: 20px;
}
.footer-address h6 {
  position: relative;
  margin-bottom: 20px;
}
.follow-us h6 {
  margin-top: 30px;
}
/* .footer-list h6:before,
.follow-us h6:before,
.footer-address h6:before {
  content: "";
  position: absolute;
  background-color: #ed594e;
  width: 50px;
  height: 2px;
  left: 0;
  right: 0;
  bottom: -10px;
} */
.footer-logo img {
  width: 180px;
}
.contact_card p {
  margin-bottom: 0px;
}

/*testimonial css*/
.large-container {
  position: static;
  max-width: 1580px;
  padding: 0px 30px;
  margin: 0 auto;
}
.testimonial-section {
  position: relative;
  padding-top: 40px;
  /* padding-bottom: 210px; */
  padding-bottom: 175px;
  overflow: hidden;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.testimonial-section:before {
  position: absolute;
  left: -200px;
  top: 22%;
  background-image: url(../images/ring-circle.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 701px;
  height: 756px;
  content: "";
  -webkit-animation: fa-spin 25s infinite alternate;
  -moz-animation: fa-spin 25s infinite alternate;
  -ms-animation: fa-spin 25s infinite alternate;
  -o-animation: fa-spin 25s infinite alternate;
  animation: fa-spin 25s infinite alternate;
}
.testimonial-section .sec-title {
  position: relative;
  margin-bottom: 115px;
}
.testimonial-section .sec-title .title {
  margin-bottom: 20px;
}
.testimonial-section .testimonial-carousel {
  position: relative;
  max-width: 1100px;
  margin: 0 -50px;
}
.testimonial-block {
  position: relative;
  padding: 50px;
}
.testimonial-block .inner-box {
  padding: 80px 105px;
  background-color: #fff;
  box-shadow: 0 0 50px rgba(226, 222, 232, 0.75);
  min-height: 400px;
}
.testimonial-block .text {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 32px;
  color: #282331;
  font-weight: 400;
  margin-bottom: 50px;
}
.testimonial-block .info-box {
  position: relative;
  padding-left: 115px;
  padding-top: 10px;
}
.testimonial-block .info-box .thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 82px;
  width: 82px;
}
.testimonial-block .info-box .thumb img {
  border: 6px solid #e5e6fa;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  width: 100%;
  box-shadow: 0 45px 45px rgba(147, 147, 147, 0.35);
}
.testimonial-block .info-box .name {
  position: relative;
  display: block;
  font-size: 21px;
  line-height: 1.2em;
  color: #382c4d;
  font-weight: 700;
  margin-bottom: 10px;
}
.testimonial-block .info-box .designation {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #8053f7;
  font-weight: 400;
}
.testimonial-carousel .owl-nav {
  position: absolute;
  right: 75px;
  bottom: 70px;
}
.testimonial-carousel .owl-nav .owl-next .arrow-right,
.testimonial-carousel .owl-nav .owl-prev .arrow-left {
  position: relative;
  display: inline-block;
  height: 75px;
  width: 75px;
  line-height: 75px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
}
/* .testimonial-carousel .owl-nav .owl-next .arrow-right:hover,
.testimonial-carousel .owl-nav .owl-prev .arrow-left:hover {
  background-color: #00df97 !important;
  box-shadow: 0 24px 24px rgba(187, 187, 187, 0.75) !important;
} */
.owl-nav .owl-next .arrow-right,
.owl-nav .owl-prev .arrow-left {
  position: relative !important;
  display: inline-block !important;
  height: 9px !important;
  width: 43px !important;
  background-image: url(../images/arrow-left-2.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.owl-carousel .owl-stage-outer {
  -webkit-transform: translate3d(3px, 90px, 0px) !important;
}
.arrow-right {
  background-image: url(../images/arrow-right-2.png) !important;
}
.testimonial-section .thumb-layer {
  position: absolute;
  right: 30px;
  top: 120px;
}
.testimonial-section .thumb-layer .image {
  position: relative;
  margin-right: 0;
}
.testimonial-section .thumb-layer .image img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.owlcarousel-slider .owl-nav {
  position: absolute !important;
  right: 40% !important;
  top: 70% !important;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  font-size: 30px !important;
}
.owlcarousel-slider .owl-prev {
  margin-right: 20px !important;
}
.owlcarousel-slider .owl-next,
.owlcarousel-slider .owl-prev {
  position: relative !important;
  display: inline-block !important;
  height: 75px !important;
  width: 75px !important;
  line-height: 75px !important;
  text-align: center !important;
  border-radius: 50% !important;
  background-color: #ffffff !important;
  transition: all 300ms ease !important;
}
.owlcarousel-slider .owl-next:hover,
.owlcarousel-slider .owl-prev:hover {
  background-color: #f77842 !important;
  box-shadow: 0 24px 24px rgba(187, 187, 187, 0.75) !important;
}
.pt-100 {
  padding-top: 100px;
}

.service_naw_card h4 span {
  font-weight: 400;
}
.service_naw_card h4 {
  font-size: 22px;
}
::selection {
  background-color: #f3a691;
}

/* ------ achievements page css start ------- */
.footer-content p {
  line-height: 28px;
}
.ach_section_wrapper {
  background-color: #d3d3d34d;
  /* padding-top: 80px; */
  padding-top: 30px;
  /* padding-bottom: 80px; */
  padding-bottom: 50px;
  position: relative;
}
.ach_section_wrapper:before {
  content: " ";
  position: absolute;
  top: 0;
  font-size: 50px;
  opacity: 0.1;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.ach_section_wrapper h3 {
  font-size: 36px;
}
/* --- service page css start ----  */

.services-icons .card-direction .icon-box {
  padding-left: 0px;
}

.card-direction {
  border: 1px solid #e1e1e1;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 4px;
  transition: 0.4s all ease-in-out;
  display: inline-block;
}
.services-icons .box-icon {
  padding: 0px;
}

.card-direction:hover {
  border: 1px solid #ffffff;
  transform: translateY(-10px);
  transition: 0.4s all ease-in-out;
  box-shadow: 0px 12px 20px 0px rgb(244 67 54 / 12%);
}
.card-direction h5 {
  color: #000;
}
.card-direction {
  text-decoration: none;
}
.button_box {
  /* text-align: center; */
}
.navitemlink .service_item {
  margin-left: 0px;
}
.card-direction .icon-box {
  margin-bottom: 18px;
}
a.ach_img {
  transition: 0.4s all ease-in-out;
}
a {
  display: inline-block;
}
.ach_img:hover {
  transform: translateY(-10px);
  transition: 0.4s all ease-in-out;
}

.sr_card {
  position: relative;
  box-shadow: 0px 4px 20px 0px rgb(157 157 157 / 22%);
  overflow: hidden;
}
.sr_content {
  padding: 20px;
}
.sr_card:before {
  content: "";
  position: absolute;
  background: #e93a4d;
  transform: scale(0);
  transition: all 300ms linear;
  transform-origin: right bottom;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 400px 0px 0px 0px;
}
.sr_card:hover:before {
  transform: scale(1.5);
  transition: all 300ms linear;
}

.sr_card:hover h4,
.sr_card:hover p {
  color: #fff;
}

.sr_icons {
  background: #fff;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -10px;
  right: 100px;
  left: auto;
  z-index: 1;
  text-align: center;
  line-height: 100px;
  transition: 0.4s all ease-in-out;
  bottom: auto;
}
.sr_card:hover .sr_icons {
  transform: rotate(180deg) scale(-1);
  transition: 0.4s all ease-in-out;
}
.service_card_01 .sr_card .sr_icons {
  top: -10px;
  left: 100px;
  right: auto;
  bottom: auto;
}
.service_card_03 .sr_card .sr_icons {
  top: 140px;
  left: 70px;
}

.service_card_02 .sr_card .sr_icons {
  top: 140px;
  right: 70px;
}
.service_card_02,
.sr_card {
  margin-bottom: 30px;
}
.sr_content p {
  font-size: 16px;
}
/* contact us design */

section.location-card-section {
  margin-top: -50px;
  margin-bottom: 50px;
}
.contact_section_title h4 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}
.contact_section_title {
  margin-bottom: 30px;
}
.contact_form_section {
  background-color: #feeee6;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* new services page css start  */

.service-block-one .inner-box {
  position: relative;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 20px;
  text-align: center;
  padding: 50px 30px;
  transition: 0.5s;
}

.service-block-one .icon:before {
  background-color: #f77842;
}
.service-block-one .icon:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  transition: 0.9s;
  transform: scale(0.5);
  opacity: 0;
}

.service-block-one .icon:before {
  background-color: #f77842;
}
.service-block-one .icon:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  transition: 0.9s;
  transform: scale(0.5);
  opacity: 0;
}
.service-block-one h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}
.service-block-one .text {
  position: relative;
  margin-bottom: 20px;
}
.text {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #808080;
  margin: 0px 0px 15px;
}
.btn-style-one.style-two {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 18px 35px;
  color: #061a3a;
  box-shadow: none;
}
.btn-style-one {
  background-color: #f77842;
}
.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 24px;
  color: #fff;
  padding: 20px 35px;
  font-weight: 600;
  border-radius: 32px 0 32px 32px;
  overflow: hidden;
  text-transform: capitalize;
  /* font-family: "Poppins", sans-serif; */
  vertical-align: middle;
  box-shadow: 0px 15px 40px 0px rgba(32, 171, 148, 0.2);
}
.theme-btn {
  display: inline-block;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.theme-btn {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-style-one.style-two:hover {
  /* border-color: #f77842; */
  /* background-color: #f77842; */
}
.btn-style-one.style-two:hover {
  /* border-color: #f77842; */
}
.btn-style-one.style-two:hover {
  /* color: #fff; */
  box-shadow: 0px 15px 40px 0px rgba(32, 171, 148, 0.2);
}
.btn-style-one.style-two {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 18px 35px;
  /* color: #061a3a; */
  box-shadow: none;
  text-decoration: none !important;
}
.btn-style-one:hover {
  color: #fff;
  background-color: #2b2c2e;
}

.btn-style-one:hover,
.btn-style-one:focus,
.btn-style-one:visited {
  text-decoration: none !important;
  outline: none;
}

.btn-style-one {
  background-color: #f77842;
}
.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 24px;
  color: #fff;
  padding: 20px 35px;
  font-weight: 600;
  border-radius: 32px 0 32px 32px;
  overflow: hidden;
  text-transform: capitalize;
  /* font-family: "Poppins", sans-serif; */
  vertical-align: middle;
  box-shadow: 0px 15px 40px 0px rgba(32, 171, 148, 0.2);
}
.btn-style-one.style-two {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 18px 35px;
  color: #061a3a;
  box-shadow: none;
}
.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 24px;
  color: #fff;
  padding: 20px 35px;
  font-weight: 600;
  border-radius: 32px 0 32px 32px;
  overflow: hidden;
  text-transform: capitalize;
  /* font-family: "Poppins", sans-serif; */
  vertical-align: middle;
  box-shadow: 0px 15px 40px 0px rgba(32, 171, 148, 0.2);
}

.btn-style-one.style-two:hover {
  border-color: #f77842;
  background-color: #f77842;
}

.btn-style-one.style-two:hover {
  border-color: #f77842;
}
.btn-style-one.style-two:hover {
  color: #fff;
  box-shadow: 0px 15px 40px 0px rgba(255 181 71 / 24%);
}
.btn-style-one.style-two {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 18px 35px;
  color: #061a3a;
  box-shadow: none;
}
.btn-style-one.style-two:before {
  background: #f77842;
}
.btn-style-one:hover:before {
  width: 100%;
}
.btn-style-one:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  content: "";
  background-color: #2b2c2e;
  transition: 0.5s;
  border-radius: 32px 0 32px 32px;
}
.btn-style-one span {
  position: relative;
}
.service-block-one .icon {
  color: #f77842;
}
.service-block-one .icon {
  position: relative;
  width: 110px;
  height: 110px;
  line-height: 110px;
  text-align: center;
  margin: 0 auto;
  background-color: #f9f3e8;
  font-size: 50px;
  border-radius: 50%;
  margin-bottom: 20px;
  transition: 0.5s;
}

.service-block-one .inner-box:hover {
  box-shadow: 0px 40px 60px 0px rgba(37, 59, 112, 0.1);
}
.service-block-one .inner-box {
  position: relative;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 20px;
  text-align: center;
  padding: 50px 30px;
  transition: 0.5s;
}
.service-block-one .inner-box:hover .icon {
  transform: rotate(180deg);
}
.service-block-one .inner-box:hover .icon {
  color: #fff;
}
.service-block-one .inner-box:hover .icon:before {
  transform: scale(1);
  opacity: 1;
}
.service-block-one .icon:before {
  background-color: #fff;
}
.service-block-one .icon:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  transition: 0.9s;
  transform: scale(0.5);
  opacity: 0;
}
.service-block-one .inner-box:hover .icon {
  color: #fff;
}
.service-block-one .icon img {
  position: relative;
}

/* --- service page css start ----  */

.services-icons .card-direction .icon-box {
  padding-left: 0px;
}

.card-direction {
  border: 1px solid #e1e1e1;
  padding: 20px;
  margin-bottom: 80px;
  border-radius: 4px;
  transition: 0.4s all ease-in-out;
  display: inline-block;
}
.services-icons .box-icon {
  padding: 0px;
}

.card-direction:hover {
  border: 1px solid #ffffff;
  transform: translateY(-10px);
  transition: 0.4s all ease-in-out;
  box-shadow: 0px 12px 20px 0px rgb(244 67 54 / 12%);
}
.card-direction h5 {
  color: #000;
}
.card-direction {
  text-decoration: none;
}
.button_box {
  text-align: center;
  margin-top: 20px;
}
.navitemlink .service_item {
  margin-left: 0px;
}

/*service page css*/
.service-block-one .inner-box {
  position: relative;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 20px;
  text-align: center;
  padding: 50px 30px;
  transition: 0.5s;
}

.service-block-one .icon:before {
  background-color: #f77842;
}
.service-block-one .icon:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  transition: 0.9s;
  transform: scale(0.5);
  opacity: 0;
}

.service-block-one .icon:before {
  background-color: #f77842;
}
.service-block-one .icon:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  transition: 0.9s;
  transform: scale(0.5);
  opacity: 0;
}
.service-block-one h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}
.service-block-one .text {
  position: relative;
  margin-bottom: 20px;
}
.text {
  font-size: 19px;
  line-height: 28px;
  font-weight: 400;
  color: #808080;
  margin: 0px 0px 15px;
}
.btn-style-one.style-two {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 18px 35px;
  color: #061a3a;
  box-shadow: none;
}
.btn-style-one {
  background-color: #f77842;
}
.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 24px;
  color: #fff;
  padding: 20px 35px;
  font-weight: 600;
  border-radius: 32px 0 32px 32px;
  overflow: hidden;
  text-transform: capitalize;
  /* font-family: "Poppins", sans-serif; */
  vertical-align: middle;
  box-shadow: 0px 15px 40px 0px rgba(32, 171, 148, 0.2);
}
.theme-btn {
  display: inline-block;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.theme-btn {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-style-one.style-two:hover {
  /* border-color: #f77842; */
  /* background-color: #f77842; */
}
.btn-style-one.style-two:hover {
  /* border-color: #f77842; */
}
.btn-style-one.style-two:hover {
  /* color: #fff; */
  box-shadow: 0px 15px 40px 0px rgba(32, 171, 148, 0.2);
}
.btn-style-one.style-two {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 18px 35px;
  /* color: #061a3a; */
  box-shadow: none;
  text-decoration: none !important;
}
.btn-style-one:hover {
  color: #fff;
  background-color: #2b2c2e;
}

.btn-style-one:hover,
.btn-style-one:focus,
.btn-style-one:visited {
  text-decoration: none !important;
  outline: none;
}

.btn-style-one {
  background-color: #f77842;
}
.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 24px;
  color: #fff;
  padding: 20px 35px;
  font-weight: 600;
  border-radius: 32px 0 32px 32px;
  overflow: hidden;
  text-transform: capitalize;
  /* font-family: "Poppins", sans-serif; */
  vertical-align: middle;
  box-shadow: 0px 15px 40px 0px rgba(32, 171, 148, 0.2);
}
.btn-style-one.style-two {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 18px 35px;
  color: #061a3a;
  box-shadow: none;
}
.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 24px;
  color: #fff;
  padding: 20px 35px;
  font-weight: 600;
  border-radius: 32px 0 32px 32px;
  overflow: hidden;
  text-transform: capitalize;
  /* font-family: "Poppins", sans-serif; */
  vertical-align: middle;
  box-shadow: 0px 15px 40px 0px rgba(32, 171, 148, 0.2);
}

.btn-style-one.style-two:hover {
  border-color: #f77842;
  background-color: #f77842;
}

.btn-style-one.style-two:hover {
  border-color: #f77842;
}
.btn-style-one.style-two:hover {
  color: #fff;
  box-shadow: 0px 15px 40px 0px rgba(255 181 71 / 24%);
}
.btn-style-one.style-two {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 18px 35px;
  color: #061a3a;
  box-shadow: none;
}
.btn-style-one.style-two:before {
  background: #f77842;
}
.btn-style-one:hover:before {
  width: 100%;
}
.btn-style-one:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
  content: "";
  background-color: #2b2c2e;
  transition: 0.5s;
  border-radius: 32px 0 32px 32px;
}
.btn-style-one span {
  position: relative;
}
.service-block-one .icon {
  color: #f77842;
}
.service-block-one .icon {
  position: relative;
  width: 110px;
  height: 110px;
  line-height: 110px;
  text-align: center;
  margin: 0 auto;
  background-color: #f9f3e8;
  font-size: 50px;
  border-radius: 50%;
  margin-bottom: 20px;
  transition: 0.5s;
}

.service-block-one .inner-box:hover {
  box-shadow: 0px 40px 60px 0px rgba(37, 59, 112, 0.1);
}
.service-block-one .inner-box {
  position: relative;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 20px;
  text-align: center;
  padding: 50px 30px;
  transition: 0.5s;
  height: 460px;
}
.text p {
  font-size: 18px;
}
.service-block-one .inner-box:hover .icon {
  transform: rotate(180deg);
}
.service-block-one .inner-box:hover .icon {
  color: #fff;
}
.service-block-one .inner-box:hover .icon:before {
  transform: scale(1);
  opacity: 1;
}
.service-block-one .icon:before {
  background-color: #fff;
}
.service-block-one .icon:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  transition: 0.9s;
  transform: scale(0.5);
  opacity: 0;
}
.service-block-one .inner-box:hover .icon {
  color: #fff;
}
.service-block-one .icon img {
  position: relative;
}
.PhoneInput {
  display: flex;
  /* align-items: center; */
  border: 1px solid #c4c4c4;
  width: 90%;
  padding: 7.5px 14px;
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: none;
}
.PhoneInputInput:focus {
  outline: none;
}
.modal-apply {
  background: linear-gradient(130deg, #ff3856, #ff9d62);
  border: none;
}

.website-optimization ul li {
  line-height: 195%;
  font-size: 19px;
}
.PhoneInputCountryIcon--border {
  border: 1px solid transparent !important;
  box-shadow: none !important;
  background: none !important;
}
.PhoneInputCountryIcon--border:focus {
  border: 1px solid transparent !important;
}
.PhoneInputCountryIcon {
  height: 1.5em !important;
  width: 2.5em !important;
  border: 1px solid transparent !important;
}
.upload-btn .css-1q8v7ak-MuiSvgIcon-root {
  display: none;
}
.upload-btn .css-1sasres {
  border: none;
  padding: 0;
}

.desktopservice-banner {
  display: block;
}
.service-mobbanner {
  display: none;
}
.contact_form .form_group input,
.contact_form .form_group textarea {
  background-color: #fff !important;
  border: 1px solid #c9c9c9 !important;
}
.contact_form .input-phone-number.PhoneInput input {
  border: none !important;
}
.contact_form .input-phone-number.PhoneInput {
  width: 100% !important;
  padding: 10px 14px !important;
  border-radius: 5px !important;
}

/*media screen css by dipesh 15-04-23*/

/* old media query start from here */
/*media query*/
@media only screen and (max-width: 2500px) and (min-width: 1921px) {
  .headerlogo {
    /* height: 109px; */
  }
}
@media only screen and (max-width: 1440px) {
  .banner-section h1 {
    font-size: 40px;
  }
  .banner-section {
    padding-top: 82px;
  }
  .makeStyles-link-3,
  .get-a-quote {
    font-size: 17px !important;
  }
  .banner-section .banner-col.text .banner-col-body {
    bottom: 55px;
    right: -195px;
  }
  .all-title {
    font-size: 44px;
  }
  .headerlogo {
    /* height: 87px; */
  }
  .navbar {
    width: 100%;
  }
  .navitemlink .navitem {
    font-size: 18px;
    margin-left: 20px;
  }
  .img-responsive {
    width: 100%;
  }
  .banner_info_wrapper {
    margin-left: 14%;
  }
  .testimonial-section .testimonial-carousel {
    max-width: 970px !important;
  }
}
@media screen and (max-width: 1366px) {
  .hero_banner_image .img-responsive {
    width: 100%;
  }
  .testimonial-section .testimonial-carousel {
    max-width: 920px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .headerlogo {
    /* height: 77px; */
  }
  .banner-btn-body a {
    font-size: 16px;
  }
  .testimonial-section .content p {
    font-size: 15px;
  }
  .sub-heading {
    font-size: 16px;
  }
  .all-title {
    font-size: 28px;
  }
  .navbar {
    margin-left: -75px;
    width: 100%;
  }
  .banner-section .banner-col.text .banner-col-body {
    bottom: 30px;
  }
  .working-dotted-body {
    padding: 20px 55px;
  }
  p {
    font-size: 16px;
  }
  .owlcarousel-slider .owl-nav {
    position: absolute !important;
    right: 40% !important;
    top: 78% !important;
  }
}
@media only screen and (max-width: 1240px) {
  .owlcarousel-slider .owl-nav {
    position: absolute !important;
    right: 40% !important;
    top: 90% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .banner-body {
    flex-direction: column;
  }
  .banner-btn-body {
    justify-content: center;
  }
  .banner-section {
    padding-top: 100px;
  }
  .MuiButtonBase-root {
    padding: 20px !important;
    margin: 0 20px !important;
    border: 10px solid #d7242c !important;
  }
  .MuiToolbar-gutters {
    background: #040819d1;
    position: fixed !important;
    overflow: hidden;
    width: 100%;
    align-items: center !important;
    justify-content: space-between;
    min-height: 62px;
  }
  .MuiListItem-gutters {
    padding: 10px 0 !important;
  }
  .MuiListItemText-root a {
    color: #fff;
    font-size: 30px;
    text-decoration: none;
  }
  .MuiDrawer-paperAnchorLeft {
    right: 60% !important;
    padding: 20px;
    /* justify-content: center; */
    background-color: #000 !important;
  }
  .mobile-responsive .dropdown-toggle.nav-link {
    font-size: 24px;
  }
  .mobile-responsive .dropdown-menu.show {
    background-color: #ff5722;
    width: 260px;
  }
  .mobile-responsive .MuiListItemText-root a {
    padding: 10px;
  }
  .headerlogo img {
    width: 85%;
  }
  .banner-section .banner-col.text {
    width: 100%;
    order: 2;
  }
  .banner-section .banner-col.text .banner-col-body {
    position: initial;
    text-align: center;
    padding: 50px 10px;
  }
  .banner-section .banner-col.img {
    width: 100%;
  }
  .sub-heading {
    font-size: 14px;
  }
  .headerlogo {
    /* height: 98px; */
    padding: 5px 20px;
  }
  p {
    font-size: 13px;
  }
  .testimonial-section .testimonial-col {
    padding: 20px 10px;
  }
  .testimonial-section .content p {
    font-size: 13px;
  }
  .what-we-provide-body .what-we-provide-col {
    height: 400px;
    padding: 10px;
    margin: 10px 10px;
  }
  .responsive-header {
    display: block;
    padding: 15px;
    width: 45%;
  }
  .main-header {
    display: none;
  }
  .all-title {
    font-size: 28px;
  }
  .what-we-provide-body .what-we-provide-col p {
    line-height: 25px;
  }
  .working-dotted-body .dotted-img {
    width: 65px;
    height: 65px;
  }
  .working-dotted-body .dotted-img img {
    width: 100%;
  }
  .MuiPaper-elevation16 .MuiSvgIcon-root {
    color: #fff;
    font-size: 35px;
  }
  .MuiListItem-button {
    border: none !important;
    margin-left: 0 !important;
  }
  .MuiButtonBase-root .MuiListItemText-root {
    /* flex: 0 1 auto !important; */
  }
  .upload-btn .MuiButtonBase-root {
    border: none !important;
  }
  .upload-btn .MuiTypography-caption {
    display: none !important;
  }
  .dialog-scroll .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    margin: 9px !important;
  }
  .desc-text {
    width: 290px !important;
  }
  .dialog-scroll .MuiTextField-root,
  .PhoneInput {
    width: 100% !important;
  }
  .dialog-scroll {
    overflow-y: scroll !important;
  }
}

@media only screen and (max-width: 991px) {
  .banner_info_wrapper {
    margin-top: 30px;
  }
  .hero_banner_image .img-responsive {
    width: 65%;
  }
  .main_header_wrapper {
    position: relative;
    margin-top: 60px;
    margin-bottom: 25px;
  }
  .hero_banner_image {
    text-align: center;
  }

  .row.align-items-center.who-we .col-md-6 {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .sub-heading {
    width: 100%;
  }
  .space {
    margin: 0 10px;
  }
  .working-process-row .justify-content-between {
    justify-content: center !important;
  }
  .working-dotted-body .dotted-img {
    width: 40px;
    height: 40px;
  }
  .working-dotted-body p img {
    width: 25px;
  }
  .working-dotted-body {
    padding: 0px;
  }
  .working-process-section .working-title {
    min-height: 10px;
    margin-bottom: 0;
  }
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
  .mobile-view .dotted-img {
    padding: 10px;
  }
  p {
    line-height: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .headerlogo {
    width: auto;
  }
  .testimonial-section .testimonial-col {
    height: 450px;
  }
  .technology-row .col-sm-3 {
    width: 50%;
  }
  .space {
    margin: 0 0;
  }
  .banner-btn-body a {
    width: 145px;
    line-height: 30px;
    font-size: 13px;
  }
  .what-we-provide {
    padding: 0;
  }
  .what-we-provide-body .what-we-provide-col img {
    height: 100px;
  }
  .headerlogo {
    /* height: 65px; */
  }
  .headerlogo img {
    width: 55%;
  }
  .MuiButtonBase-root {
    padding: 10px !important;
  }
  .working-process-section {
    clip-path: none;
  }
  .banner-section h1 {
    font-size: 34px;
  }
  .MuiDrawer-paperAnchorLeft {
    right: 40% !important;
  }
  .MuiListItem-gutters {
    padding: 10px 0 !important;
  }
  .MuiListItemText-root a {
    font-size: 16px;
  }
  .navbar-brand {
    width: 83%;
    padding-top: 10px;
  }
  .footer-section .footer-sec-col {
    padding: 10px 0;
  }
  .about-us-col {
    margin: 20px 0;
  }
  .sub-heading {
    width: 100%;
    padding: 10px;
  }
  .about-us-col .icon-text .fa.fa-info-circle,
  .about-us-col .icon-text h4 {
    font-size: 15px;
  }
  .navbar-header .top-header {
    flex-direction: column;
  }
  .banner-section {
    padding-top: 64px;
  }
  .banner-body {
    flex-direction: column;
  }
  .banner-section .banner-col.img {
    width: 100%;
  }
  .title-clr {
    font-size: 14px;
  }
  .banner-section .banner-col.text {
    width: 100%;
    justify-content: center;
    padding: 20px 0;
  }
  .footer-section .footer-list {
    align-items: flex-start;
    justify-content: center;
  }
  .footer-section .subscribe {
    width: 100%;
  }
  .footer-section .follow-us {
    text-align: left;
  }
  .footer-section .follow-us .social-icon {
    justify-content: left;
  }
  .working-dotted-body {
    flex-direction: column;
    align-items: center;
  }
  .working-dotted-body .dotted-img {
    width: 100px;
    height: 110px;
  }
  .technology-section,
  .working-process-section {
    padding: 50px 0;
  }
  .working-dotted-body {
    padding: 20px;
  }
  .our-clients-section .sponser-img {
    margin: 10px 15px;
  }
  .testimonial-section:before {
    display: none;
  }
  .testimonial-section {
    padding-bottom: 48px;
  }
  .testimonial-block .inner-box {
    min-height: 540px;
    width: 100%;
    /* border: none !important; */
    box-shadow: none !important;
  }
  .testimonial-section .all-title {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 320px) {
  .MuiListItem-gutters {
    padding: 0 5px !important;
  }
  .MuiListItemText-root a {
    font-size: 13px;
  }
}
/* old media query start from here */

/*/////////////////////Home page media query css starts from here 14-04-23/////////////////////*/

/*============================= responsive aboutus page start ===============================*/

@media only screen and (max-width: 2500px) and (min-width: 1921px) {
  .headerlogo {
    /* height: 109px; */
  }
}
@media only screen and (max-width: 1440px) {
  .banner-section h1 {
    font-size: 40px;
  }
  .banner-section {
    padding-top: 82px;
  }
  .makeStyles-link-3,
  .get-a-quote {
    font-size: 17px !important;
  }
  .banner-section .banner-col.text .banner-col-body {
    bottom: 55px;
    right: -195px;
  }
  .all-title {
    font-size: 44px;
  }
  .headerlogo {
    /* height: 87px; */
  }
  .navbar {
    width: 100%;
  }
  .navitemlink .navitem {
    font-size: 18px;
    margin-left: 20px;
  }
  .img-responsive {
    width: 100%;
  }
  .banner_info_wrapper {
    margin-left: 14%;
  }
  .testimonial-section .testimonial-carousel {
    max-width: 970px !important;
  }
  .contactus-section,
  section.about-us-section.career-section {
    background-size: 100%;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 1366px) {
  .hero_banner_image .img-responsive {
    width: 100%;
  }
  .testimonial-section .testimonial-carousel {
    max-width: 920px !important;
  }
  .large-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .what-we-provide-col {
    height: 500px;
  }

  .what-we-provide-col {
    height: 430px;
  }
}
@media only screen and (max-width: 1280px) {
  .footer-section:after {
    width: 150px;
  }
  .banner-btn-body a {
    font-size: 16px;
  }
  .testimonial-section .content p {
    font-size: 15px;
  }
  .sub-heading {
    font-size: 16px;
  }
  .all-title {
    font-size: 28px;
  }
  .navbar {
    margin-left: -75px;
    width: 100%;
  }
  .banner-section .banner-col.text .banner-col-body {
    bottom: 30px;
  }
  .working-dotted-body {
    padding: 20px 55px;
  }
  p {
    font-size: 16px;
  }
  .testimonial-section .testimonial-carousel {
    max-width: 850px !important;
  }
  .os_top_header {
    box-shadow: 1px 4px 16px 0px rgb(79 79 79 / 26%);
  }
  .web-vital-body .col-two li .MuiSvgIcon-root {
    position: absolute;
    right: -80px;
    width: 3em;
    height: 3em;
  }
  .vital-three-section .MuiSvgIcon-root {
    position: absolute;
    right: -95px;
    top: 115px;
    width: 3em;
    height: 3em;
  }
  .portfolio-content h4 {
    font-size: 18px;
  }
  .grid-item {
    padding: 15px;
  }
  .portfolio-image img {
    width: 100%;
  }
  .MuiToolbar-gutters {
    background: #ffffff;
    /* position: fixed !important;
    overflow: hidden; */
    width: 100%;
    align-items: center !important;
    justify-content: space-between;
    top: 0;
  }
  .MuiPaper-elevation4 {
    padding: 0;
  }
  .MuiButtonBase-root {
    padding: 20px !important;
    margin: 0 20px !important;
    border: 10px solid #d7242c !important;
  }
  .headerlogo img {
    width: 180px;
    padding: 12px;
  }

  .MuiListItem-button {
    border: none !important;
    margin-left: 0 !important;
  }

  .MuiDrawer-paperAnchorLeft {
    max-width: 250px;
    width: 100%;
    padding: 20px;
    background: #ff6b6b !important;
  }
  .MuiPaper-elevation16 .MuiSvgIcon-root {
    color: #fff;
    font-size: 35px;
  }
  .main_mobile li {
    padding: 10px 0px !important;
  }
  .MuiListItemText-root a {
    font-size: 30px;
    text-decoration: none;
    color: #fff;
    padding: 0px !important;
  }
  .career-icons-img {
    min-height: 185px;
  }
}
@media screen and (max-width: 1199px) {
  .grid .grid-item .portfolio-content h4 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1024px) {
  .form_section_wrapper {
    margin-top: -40px;
  }
  .contactus-section {
    height: 200px;
  }
  section.location-card-section {
    margin-bottom: 20px;
  }
  .contact_section_title {
    margin-bottom: 30px;
  }
  .career-icons-img img {
    margin: 0px;
    width: 60%;
  }
  .banner-body {
    flex-direction: column;
  }
  .banner-btn-body {
    justify-content: center;
  }
  .banner-section {
    padding-top: 100px;
  }
  .MuiButtonBase-root {
    padding: 20px !important;
    margin: 0 20px !important;
    border: 10px solid #d7242c !important;
  }
  .about-us-section,
  .portfolio-section,
  .appservices-section {
    height: 170px;
  }
  .MuiListItem-gutters {
    padding: 10px 0 !important;
  }
  .MuiListItemText-root a {
    color: #fff;
    font-size: 18px;
    text-decoration: none;
  }
  .mobile-responsive .dropdown-toggle.nav-link {
    font-size: 24px;
  }
  .mobile-responsive .dropdown-menu.show {
    background-color: #ff5722;
    width: 260px;
  }
  .mobile-responsive .MuiListItemText-root a {
    padding: 10px;
  }
  .headerlogo img {
    width: 85%;
  }
  .banner-section .banner-col.text {
    width: 100%;
    order: 2;
  }
  .banner-section .banner-col.text .banner-col-body {
    position: initial;
    text-align: center;
    padding: 50px 10px;
  }
  .banner-section .banner-col.img {
    width: 100%;
  }
  .sub-heading {
    font-size: 16px;
    margin-top: -30px;
  }
  .headerlogo {
    /* height: 98px; */
    padding: 5px 20px;
  }
  p {
    font-size: 13px;
  }
  .testimonial-section .testimonial-col {
    padding: 20px 10px;
  }
  .testimonial-section .content p {
    font-size: 13px;
  }
  .what-we-provide-body .what-we-provide-col {
    height: 400px;
    padding: 10px;
    margin: 10px 10px;
  }

  .all-title {
    font-size: 28px;
  }
  .what-we-provide-body .what-we-provide-col p {
    line-height: 25px;
  }
  .working-dotted-body .dotted-img {
    width: 65px;
    height: 65px;
  }
  .working-dotted-body .dotted-img img {
    width: 100%;
  }
  .MuiPaper-elevation16 .MuiSvgIcon-root {
    color: #fff;
    font-size: 35px;
  }
  .MuiListItem-button {
    border: none !important;
    margin-left: 0 !important;
  }
  .core-web-vital-section .signal-section {
    width: 370px;
    padding: 0 35px;
  }
  .vital-three-section .MuiSvgIcon-root {
    right: -120px;
  }
  .core-web-vital-section .col-two ul {
    padding-left: 0;
  }
  .core-web-vital-section .col-body .col-one {
    flex-direction: column;
  }
  .vital-section {
    width: auto;
  }
  .vital-three-section ul {
    padding: 0;
  }
  .vision-section .vision-box p {
    font-size: 20px;
    padding: 25px 0px;
  }
  .vision-section .vision-box {
    height: 355px;
    padding: 30px;
  }
  .we-are-expert .expert-list li {
    font-size: 14px;
  }
  .ost-hero_banner_text h1 {
    font-size: 27px !important;
  }
  .ost-hero_banner_text p {
    font-size: 20px !important;
  }
  .header_bg {
    min-height: 650px !important;
  }
  .hero_banner_image .img-responsive {
    width: 80%;
  }
  .owlcarousel-slider .owl-nav {
    right: 25% !important;
    top: 100% !important; /*css add*/
  }
  .testimonial-block .text {
    font-size: 15px;
  }
  .footer-section:after {
    width: 150px;
  }
  .portfolio-section {
    height: 290px;
    margin-top: 36px;
    background-size: cover;
  }
  .portfolio-section {
    margin-top: 40px;
    background-size: cover;
    height: 199px;
  }
}
@media only screen and (min-width: 992px) {
  .sr_img {
    /* position: absolute;
    right: 0;
    bottom: 0; */
    max-width: 45%;
    margin: 0 0 0 auto;
  }
  .src_section_01 {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .sr_img img {
    max-width: 100%;
    border-radius: 400px 0px 0px 0px;
    width: 100%;
  }
  .sr_content {
    max-width: 50%;
    width: 100%;
    padding: 20px;
  }
  .sr_card {
    min-height: 300px;
  }
  .footer-section:after {
    width: 100px;
  }

  @media only screen and (max-width: 912px) {
    .portfolio-section {
      margin-top: 40px;
      background-size: cover;
      height: 199px;
    }
  }

  /*------- img card- 01 ----------*/

  .sr_img-01 {
    max-width: 45%;
  }
  .src_section-2 {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .src_section-4 {
    position: absolute;
    left: 0;
    top: 0;
  }
  .src_section-3 {
    position: absolute;
    right: 0;
    top: 0;
  }
  .sr_img-01 img {
    max-width: 100%;
    border-radius: 0px 400px 0px 0px;
    width: 100%;
  }
  .service_card_01 .sr_card:before {
    border-radius: 0px 400px 0px 0px;
    transform-origin: left bottom;
  }
  .service_card_01 .sr_content {
    margin: 0 0 0 auto;
  }

  /*--------- img card-02 ---------*/

  .service_card_02 .sr_img {
    /* position: absolute; */
    top: 0;
    right: 0;
    max-width: 45%;
  }
  .service_card_02 .sr_img img {
    border-radius: 0px 0px 0px 400px;
  }
  .service_card_02 .sr_card:before {
    border-radius: 0px 0px 0px 400px;
    transform-origin: top right;
  }

  /*--------- img card-03 --------*/

  .service_card_03 .sr_img-01 {
    /* position: absolute;
    top: 0;
    left: 0; */
    max-width: 45%;
  }
  .service_card_03 .sr_img-01 img {
    border-radius: 0px 0px 400px 0px;
  }
  .service_card_03 .sr_card:before {
    border-radius: 0px 0px 400px 0px;
    transform-origin: top left;
  }
  .service_card_03 .sr_content {
    margin: 0 0 0 auto;
  }
  .form_section_wrapper {
    position: relative;
    /* margin-top: -220px; */
  }
}
@media only screen and (max-width: 991px) {
  .footer-col {
    padding: 0px;
    position: relative;
    z-index: 9;
  }
  .banner_info_wrapper {
    margin-bottom: 40px;
    margin-top: 100px;
  }
  .contact_info_wrapper:before {
    top: -10px;
  }
  .about__area-left {
    margin-right: 0px;
  }
  .banner_btn_section,
  .ost-hero_banner_text {
    margin-top: 51px;
  }
  .shape-circle-1 {
    top: 0%;
  }
  .form-section {
    padding: 50px 0px;
  }
  .sr_card {
    display: flex;
    flex-direction: column-reverse;
  }
  .sr_content {
    text-align: center;
  }
  .src_section_01,
  .src_section-2,
  .src_section-3,
  .src_section-4 {
    margin-bottom: 40px;
    position: relative;
  }
  .src_section_01 .sr_icons,
  .service_card_01 .sr_card .sr_icons,
  .service_card_02 .sr_card .sr_icons,
  .service_card_03 .sr_card .sr_icons {
    top: 100%;
    left: 60%;
    transform: translate(-100%, -60%);
  }
  .about__area-left-image-content {
    right: 0px;
  }
  .title-clr,
  .all-title,
  .exciting-title {
    text-align: center;
  }
  .portfolio-content h4 {
    margin-bottom: 10px;
  }
  .portfolio-content p {
    font-size: 15px;
  }
  .portfolio-content > a {
    margin-bottom: 10px;
  }
  .banner_info_wrapper {
    margin-left: 0 !important;
    margin-top: 0px !important;
  }
  .right-about-img img {
    margin: 0 auto;
    display: block;
  }
  .why-choose-us-body .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .headerlogo img {
    width: 100% !important;
    padding: 0;
  }
  .headerlogo {
    width: 40%;
  }
  .portfolio-section {
    margin-top: 40px;
    background-size: cover;
    height: 199px;
  }
}

@media screen and (max-width: 768px) {
  .about-us-section,
  .portfolio-section,
  .appservices-section {
    height: 125px;
  }
  .core-web-vital-section .signal-section {
    width: auto;
    padding: 30px 35px;
    text-align: center;
  }
  .web-vital-body .MuiSvgIcon-root {
    display: none;
  }
  .about-us-right-img img,
  .why-choose-us-left img {
    justify-content: center;
    padding: 15px;
  }
  .sub-heading {
    width: 100%;
  }
  .space {
    margin: 0 10px;
  }
  .working-process-row .justify-content-between {
    justify-content: center !important;
  }
  .working-dotted-body .dotted-img {
    width: 40px;
    height: 40px;
  }
  .working-dotted-body p img {
    width: 25px;
  }
  .working-dotted-body {
    padding: 0px;
  }
  .working-process-section .working-title {
    min-height: 10px;
    margin-bottom: 0;
  }
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
  .mobile-view .dotted-img {
    padding: 10px;
  }
  p {
    line-height: 25px;
  }
  .mobile-responsive .dropdown-toggle.nav-link {
    font-size: 16px;
  }
  .career-icons-img {
    margin: 10px 0;
  }
  .what-responsiilities .list-text {
    text-align: left;
  }
  .exciting-title {
    font-size: 20px;
  }
  .what-we-provide-col p {
    font-size: 18px;
  }
  .portfolio-section {
    background-size: cover;
    margin-top: 41px;
    height: 169px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-section:after {
    width: 100px;
  }
  .service-block-one .inner-box {
    height: 300px;
  }
  .testimonial-block .inner-box {
    /* padding: 22px 91px; */
    padding: 20px;
  }
  .testimonial-block {
    margin: 0 20px;
  }
  .form_section_wrapper {
    margin-top: 6px;
    padding: 20px;
  }
  .what-we-provide-col p {
    font-size: 18px;
  }
  .why-choose-us-title h2 {
    font-size: 22px;
  }
  .industrial-work .text {
    padding: 0px 0;
  }
  .text {
    font-size: 14px;
  }
  .industrial-work {
    padding: 0px 0;
  }
  .footer-section .footer-body {
    padding: 0px 0 !important;
  }
  .about-us-section,
  .portfolio-section,
  .appservices-section {
    /* height: 80px; */
    height: 320px;
  }

  .follow-us h6 {
    display: inline-block;
  }
  .services-icons {
    padding: 0px;
  }
  .main_header_wrapper {
    margin-top: 10px;
  }
  .industrial-work {
    padding: 0px;
  }

  .why-choose-us-left img {
    max-width: 400px;
    margin: 0 auto;
  }
  .core-web-vital-section .col-body li {
    width: 330px;
  }
  .core-web-vital-section .col-body li h5 {
    font-size: 21px;
  }
  .core-web-vital-section .signal-section h4 {
    font-size: 28px;
  }
  .important-factor .loading-list li {
    width: 100%;
  }
  .all-title {
    font-size: 30px;
    padding: 10px;
    margin-top: 26px;
  }
  .why-we-choose-right {
    padding-top: 30px;
  }
  .right-center {
    margin-left: 0;
  }
  .vision-section .vision-box h5 {
    font-size: 25px;
  }
  .vision-section .vision-box p {
    font-size: 15px;
  }
  .vision-section .vision-box {
    height: 315px;
  }
  .vision-section .vision-icon img {
    width: 50%;
  }
  .vision-section .vision-box {
    border-radius: 20px 20px 145px 20px;
  }
  .icon-text h5 {
    font-size: 15px;
  }
  .why-we-choose-right .icon-img {
    padding: 0px 0px 0px 0;
  }
  .why-we-choose-right .icon-img img {
    width: 100%;
  }
  .why-we-choose-right .icons-body {
    text-align: center;
    flex-direction: column;

    margin-top: 20px;
  }
  .testimonial-section .testimonial-col {
    height: 450px;
  }
  .technology-row .col-sm-3 {
    width: 50%;
  }
  .space {
    margin: 0 0;
  }
  .banner-btn-body a {
    width: 145px;
    line-height: 30px;
    font-size: 13px;
  }
  .what-we-provide {
    padding: 0;
  }
  .what-we-provide-body .what-we-provide-col img {
    height: 100px;
  }
  .headerlogo {
    /* height: 65px; */
  }
  .headerlogo img {
    width: 55%;
  }
  .MuiButtonBase-root {
    padding: 10px !important;
  }
  .working-process-section {
    clip-path: none;
  }
  .banner-section h1 {
    font-size: 34px;
  }
  .MuiDrawer-paperAnchorLeft {
    right: 40% !important;
  }
  .MuiListItem-gutters {
    padding: 10px 0 !important;
  }
  .MuiListItemText-root a {
    font-size: 16px;
  }
  .navbar-brand {
    width: 83%;
    padding-top: 10px;
  }
  .footer-section .footer-sec-col {
    padding: 10px 0;
  }
  .about-us-col {
    margin: 20px 0;
  }
  .sub-heading {
    width: 100%;
    padding: 10px;
  }
  .about-us-col .icon-text .fa.fa-info-circle,
  .about-us-col .icon-text h4 {
    font-size: 15px;
  }
  .navbar-header .top-header {
    flex-direction: column;
  }
  .banner-section {
    padding-top: 64px;
  }
  .banner-body {
    flex-direction: column;
  }
  .banner-section .banner-col.img {
    width: 100%;
  }
  .title-clr {
    font-size: 14px;
  }
  .banner-section .banner-col.text {
    width: 100%;
    justify-content: center;
    padding: 20px 0;
  }
  .footer-section .footer-list {
    align-items: flex-start;
    justify-content: center;
  }
  .footer-section .subscribe {
    width: 60%;
  }
  .footer-section .follow-us {
    text-align: left;
  }
  .footer-section .follow-us .social-icon {
    justify-content: left;
  }
  .working-dotted-body {
    flex-direction: column;
    align-items: center;
  }
  .working-dotted-body .dotted-img {
    width: 100px;
    height: 110px;
  }
  .technology-section,
  .working-process-section {
    padding: 50px 0;
  }
  .working-dotted-body {
    padding: 20px;
  }
  .our-clients-section .sponser-img {
    margin: 10px 15px;
  }
  .portfolio-content h4 {
    font-size: 17px;
  }
  .portfolio-content p {
    font-size: 14px;
  }
  /* .portfolio-page {
    padding-top: 60px;
  } */
  .we-are-expert .expert-list li {
    width: 100%;
  }
  .we-are-expert .expert-list {
    line-height: 40px;
  }
  /* .about-us-section,
  .portfolio-section,
  .contactus-section,
  .appservices-section {
    height: 200px;
  } */
  .slick-prev {
    left: -15px;
  }
  .slick-next {
    right: -15px;
  }

  :before,
  .slick-prev:before {
    font-size: 20px !important;
  }
  .middle-center {
    margin-left: 0px;
  }
  .apply-form-section input {
    margin: 5px 0;
  }
  .career-icons-img img {
    width: 30%;
  }
  .apply-form-section .mb3 {
    margin-bottom: 0 !important;
  }
  .industrial-body .underline {
    margin: 0 auto;
  }
  .industrial-body {
    text-align: center;
    margin-top: 20px;
  }
  .slick-slider {
    padding: 0px;
  }
  .current-opening {
    clip-path: none;
  }
  .accordion-flush .accordion-item .accordion-button {
    font-size: 21px !important;
  }
  .what-responsiilities h4 {
    font-size: 21px;
  }
  .footer-section .footer-body {
    padding: 50px 0;
  }
  .footer-section .footer-list input {
    width: 60%;
  }
  .slick-next {
    right: 5px;
  }
  .testimonial-section .sec-title {
    margin-bottom: -70px;
  }
  .testimonial-block {
    padding: 0;
  }
  .testimonial-block .inner-box {
    box-shadow: none;
    border: 2px solid #f1f1f1;
  }
  .testimonial-carousel .owl-nav {
    position: relative;
    left: 0;
    bottom: 0;
    margin-top: 20px;
    text-align: center;
  }
  .testimonial-carousel .owl-next,
  .testimonial-carousel .owl-prev {
    border: 2px solid #f1f1f1;
  }
  .ach_img {
    margin-bottom: 30px;
    display: inline-block;
  }
  .owlcarousel-slider .owl-nav {
    right: 0% !important;
  }
  .desktopservice-banner {
    display: none;
  }
  .service-mobbanner {
    display: block;
  }

  .contactus-section {
    height: 140px;
    margin-top: 56px;
    width: 100%;
  }
  .contactus-body {
    width: 100%;
  }
  .contactus-text h1 {
    /* margin-top: 30px; */
    margin-top: -20px;
    text-align: center;
  }
  .contact_section_title h4 {
    font-size: 18px;
  }
  .footer-logo {
    margin-top: -25px;
  }
  .contact_info_wrapper {
    margin-top: 50px;
  }
  .aboutus-text h1 {
    margin-bottom: 68px;
  }
  .portfolio-section .aboutus-text h1 {
    margin-bottom: 0px !important;
  }
  .career-section .aboutus-text h1 {
    margin-bottom: 20px !important;
  }
  .our-clients-section {
    padding-bottom: 5px;
  }
  .our-clients-body h2 {
    margin: 10px;
    margin-top: 20px;
  }
  .our-clients-section .sponser-img {
    margin: 0;
  }
  .ost-hero_banner_text h1 {
    text-align: center;
  }
  .contact_card {
    padding: 10px;
    margin-bottom: 50px;
    margin-top: -30px;
    width: 75%;
    margin: 8px auto;
  }
  .footer-section .footer-col p {
    text-align: left;
  }
  .contact_card img {
    height: 35px;
  }
  .contact_card h4 {
    font-size: 18px;
  }
  .contact_card p {
    font-size: 15px;
    line-height: 7px;
  }
  .about__area-right-list h5:first-child {
    margin-top: 5px;
  }
  .about__area-right-list h5 {
    margin-top: 0 !important;
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 17px !important;
  }
  .about__area.section-padding .pt-5 {
    display: none;
  }
  .about__area-right-list h5 i {
    width: 15px;
    height: 0;
    line-height: 0;
    border: none;
    box-shadow: none;
    margin-right: 20px;
  }
  .why-choose-us-title h2 {
    margin: 0;
  }
  .why-we-choose-right {
    padding-top: 0;
    /* margin-top: -26px; */
  }
  .service-block-one .inner-box {
    padding: 20px 30px;
  }
  .service-block-one .icon {
    width: 0px;
    height: 60px;
    line-height: 38px;
    display: block;
    margin: 0 39%;
  }
  .service-block-one .inner-box:hover .icon:before,
  .service-block-one .inner-box:hover .icon {
    transform: none;
  }
  .industrial-body .all-title {
    margin: 0;
    padding: 0;
  }
  .about-us-right-img img {
    margin: 0 auto;
    display: block;
  }
  .icons-text p {
    font-size: 16px;
    text-align: left;
  }
  .why-choose-us-title h2 {
    font-size: 18px;
  }
  .web-design-development.py-5 {
    padding-bottom: 0 !important;
  }
  .our-clients-row .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .our-clients-section .sponser-img {
    width: 70% !important;
  }
  .sponser-img img {
    /* width: 70%; */
    width: 100%;
    /* height: 70px; */
  }
  .slick-initialized .slick-slide {
    /* width: 333px !important; */
    display: block !important;
    margin: 0 auto !important;
  }
  .what-we-provide-body {
    padding-bottom: 0;
  }
  .modal-body p {
    text-align: left;
  }
  .contact-box-body h5 {
    font-size: 21px;
  }
  .section_title h2 {
    font-size: 21px;
  }
  .text,
  p {
    font-size: 16px;
  }
  /* .aboutus-text h1 {
  margin-bottom: 7px;
  /* margin-top: -20px; */

  .our-clients-section {
    padding: 0px 0;
  }
  .why-we-choose-right h2 {
    font-size: 21px;
  }
  .portfolio_section figure {
    margin-bottom: 20px;
  }
  .btn-style-one.style-two {
    padding: 10px 30px;
    font-size: 16px;
  }
  .aboutus-text span,
  .portfolio-text span,
  .contactus-text span,
  .appservices-text span {
    color: #ffffff;
  }
  /* .about-us-body h1{
  margin-bottom: 7px;
    margin-top: -64px;
} */
  .career-icons-img:hover .on-hover {
    margin-bottom: 0;
  }
  .career-icons-img {
    min-height: 94px;
  }
  .career-icons-img img {
    margin: 5px 0 0 0;
  }
  .career-icons-img {
    height: 95px;
  }
  .contact-box-body h5 {
    padding-top: 0px;
  }
  .contact-box-body {
    height: 220px;
  }
  .contact_form_section {
    padding-bottom: 25px;
  }
  .testimonial-block {
    padding: 0;
    width: 80%;
  }
  .testimonial-block .inner-box {
    width: 90%;
  }
  .testimonial-block {
    margin: 0 65px;
  }
  .testimonial-section .testimonial-carousel {
    max-width: 861px !important;
  }
  .payment_services .aboutus-text p {
    margin-top: -60px;
  }
}

/*Dipesh css start here*/

@media only screen and (max-width: 912px) {
  .appservices-section {
    margin-top: 77px;
    background-size: cover;
    height: 153px;
  }
  .appservices-body,
  .about-us-body {
    margin-bottom: 20px;
  }
  .aboutusbg {
    background-size: cover;
    margin-top: 61px;
    height: 138px;
  }
}
@media only screen and (max-width: 820px) {
  .appservices-section {
    background-size: cover;
    margin-top: 60px;
    height: 138px;
  }
  .appservices-body,
  .about-us-body {
    margin-bottom: 50px;
  }
  .btn-style-one.style-two {
    padding: 10px 30px;
    font-size: 16px;
  }
  .careerbg {
    margin-top: 68px;
    background-size: cover;
    height: 152px;
  }
  .aboutusbg {
    background-size: cover;
    margin-top: 61px;
    height: 138px;
  }
}

@media only screen and (max-width: 540px) {
  .careerbg {
    margin-top: 63px;
    background-size: cover;
    height: 101px;
  }
  .aboutusbg {
    margin-top: 63px;
    background-size: cover;
    height: 100px;
  }
}

@media only screen and (max-width: 467px) {
  .careerbg {
    margin-top: 62px;
    background-size: cover;
    height: 97px;
  }
  .aboutusbg {
    background-size: cover;
    margin-top: 61px;
    height: 97px;
  }
}
@media only screen and (max-width: 414px) {
  .appservices-section {
    background-size: cover;
    margin-top: 60px;
    height: 100px;
  }
  .appservices-body,
  .about-us-body {
    margin-bottom: 50px;
  }
  .testimonial-section {
    padding-top: 0px;
  }
  .py-5 {
    padding-top: 0px !important;
  }
  .why-choose-us-title h2 {
    font-size: 21px;
    margin-top: 10px;
  }
  .services-icons .icons-col {
    min-height: 120px;
  }
  .about-us-section {
    background-size: cover;
    /* margin-top: 60px; */
    height: 100px;
  }
  /* .sponser-img img{
    width: 76%;
    height: 90px;
  } */
  .why-we-choose-right h2 {
    font-size: 21px;
  }
  .why-we-choose-right ul li {
    font-size: 18px;
    line-height: 180%;
  }
  .website-optimization ul li {
    line-height: 210%;
    font-size: 16px;
  }
  .pt-4 {
    padding-top: 0.5rem !important;
  }
  .h5,
  h5 {
    font-size: 21px !important;
    margin-top: 10px !important;
  }
  .what-we-provide-body .what-we-provide-col {
    height: 330px;
    padding: 10px;
    margin: 10px 20px;
    /* margin-top: -30px; */
  }
  .careerbg {
    margin-top: 58px;
    background-size: cover;
    height: 100px;
  }
  .aboutusbg {
    margin-top: 58px;
    background-size: cover;
    height: 100px;
  }
}
@media only screen and (max-width: 390px) {
  .sponser-img img {
    /* width: 60%;
      margin-left: 70px; */
    width: 89%;
    margin-left: 10px;
  }
  .about__area-right-list h5 {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 375px) {
  .appservices-section {
    background-size: cover;
    margin-top: 58px;
    height: 100px;
  }
  .appservices-body,
  .about-us-body {
    margin-bottom: 50px;
  }
  .py-5 {
    padding-top: 0px !important;
  }
  .services-icons .icons-col {
    min-height: 120px;
    margin-top: 10px;
  }
  /* .sponser-img img{
      width: 76%;
      height: 90px;
    } */
  .why-we-choose-right h2 {
    font-size: 21px;
  }
  .why-we-choose-right ul li {
    font-size: 18px;
    line-height: 180%;
  }
  .website-optimization ul li {
    line-height: 210%;
    font-size: 18px;
  }
  .pt-4 {
    padding-top: 0.5rem !important;
  }
  .h5,
  h5 {
    font-size: 21px !important;
    margin-top: 10px !important;
  }
  .what-we-provide-body .what-we-provide-col {
    height: 330px;
    padding: 10px;
    margin: 10px 20px;
    margin-top: 10px;
  }
  .careerbg {
    margin-top: 58px;
    background-size: cover;
    height: 100px;
  }
  .aboutusbg {
    margin-top: 58px;
    background-size: cover;
    height: 100px;
  }
}
/* .about-us-section-bg-img
{
  margin-top: -50px;
}
  }

@media only screen and (max-width: 320px){
  .appservices-section{
    background-size: cover;
    margin-top: 56px;
    height: 100px;
  }
  .appservices-body, .about-us-body{
    margin-bottom: 50px
  }
  .why-we-choose-right ul li{
    font-size: 18px;
    line-height: 180%;
  }
 .sponser-img img {
    width: 90%;
    height: 80px;
}
.website-optimization ul li {
  line-height: 210%;
  font-size: 18px;
}
.pt-4{
  padding-top: 0.5rem !important;
}
.h5, h5 {
  font-size: 18px !important;
  margin-top: 10px !important;
}
.what-we-provide-body .what-we-provide-col {
  height: 330px;
  padding: 10px;
  margin: 10px 20px;
  margin-top: -30px;
}
.careerbg {
  margin-top: 63px;
  background-size: cover;
  height: 100px;
}
.aboutusbg{
  margin-top: 63px;
  background-size: cover;
  height: 100px;
}
.portfolio-section{
  
  margin-top: 0px;
  background-size: cover;
  height: 120px;
}
}
@media only screen and (max-width: 280px)
{
  .appservices-section{
    background-size: cover;
    margin-top: 50px;
    height: 97px;
  }
  .sponser-img img{
    width: 76%;
    height: 90px;
  }
  .why-we-choose-right h2{
    font-size: 22px;
  }
  .about-us-section {
    background-size: cover;
    margin-top: 49px;
    height: 100px;
}
.sponser-img img {
  width: 90%;
  height: 80px;
}
.website-optimization ul li {
  line-height: 210%;
  font-size: 18px;
}
.pt-4{
  padding-top: 0.5rem !important;
}
.h5, h5 {
  font-size: 18px !important;
  margin-top: 10px !important;
}
.what-we-provide-body .what-we-provide-col {
  height: 330px;
  padding: 10px;
  margin-top: -30px;
  margin: 10px 20px;
}
.careerbg {
  margin-top: 53px;
  background-size: cover;
  height: 100px;
}
.aboutusbg{
  margin-top:49x;
  background-size: cover;
  height: 100px;
}
.portfolio-section{
  
  margin-top: 0px;
  background-size: cover;
  height: 120px;
}
} 


































@media screen and (max-width:360px) {
  .ost-hero_banner_text h1 {
    font-size: 25px !important;
}
.portfolio_section input:checked ~ label {
  padding: 11px 10px;
}
}



/*=================================== about us section end =========================================*/

@media only screen and (max-width: 821px) {
  .portfolio-section {
    margin-top: 0px;
    background-size: cover;
    height: 160px;
  }
}
@media only screen and (max-width: 820px) {
  .portfolio-section {
    margin-top: 0px;
    background-size: cover;
    height: 160px;
  }
}
@media only screen and (max-width: 320px) {
  .MuiListItem-gutters {
    padding: 0 5px !important;
  }
  .MuiListItemText-root a {
    font-size: 13px;
  }
  .portfolio-section {
    margin-top: 30px;
    background-size: cover;
    height: 199px;
  }
}

@media screen and (max-width: 991px) {
  .grid .grid-item .portfolio-content h4 {
    margin-bottom: 10px;
  }
  .grid .grid-item .portfolio-content p {
    font-size: 15px;
  }
  .grid .grid-item .portfolio-content a {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .grid .grid-item .portfolio-content h4 {
    font-size: 17px;
  }
  .grid .grid-item .portfolio-content p {
    font-size: 14px;
  }
  .portfolio_section label {
    padding: 12px 10px;
    font-size: 14px;
  }
  .portfolio_section form {
    margin: 0;
  }
  section.about-us-section.career-section {
    background-position: inherit;
  }
  section.about-us-section.career-section {
    padding-top: 63px;
  }
  .careerbg {
    height: 65px;
  }
  .row.align-items-center.who-we {
    /* padding-bottom: 20px; */
    padding-bottom: 5px;
  }
  .ach_section_wrapper .mt-5.mb-5 {
    display: none;
  }
  .PhoneInputCountry {
    display: inline-flex !important;
  }
  .PhoneInputInput {
    flex: inherit !important;
  }
  .industrial-body .all-title {
    font-size: 20px;
  }
  .services-icons .icon-box img {
    width: 85%;
  }
}
@media screen and (max-width: 575px) {
  .grid .grid-item .portfolio-content h4 {
    font-size: 16px;
  }
  .portfolio-content h4 {
    font-size: 16px;
  }
  .grid .grid-item .portfolio-overlay:before {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
}

.portfolio_section figure {
}

@media only screen and (max-width: 480px) {
  .about__area-left-image-content h2 {
    font-size: 24px;
    line-height: 29px;
  }
  /* .about__area-left {
  margin-bottom: 95px;
} */
  .subtitle-one {
    font-size: 22px;
    text-align: center;
  }
  /* .about__area-right-list h5 {
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 5px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
} */
  .ach_section_wrapper {
    padding-top: 15px;
    padding-bottom: 0px;
  }
  .banner_info_wrapper {
    margin-top: 58px;
  }
  .text,
  p {
    font-size: 16px;
    text-align: center;
  }
  .ach_section_wrapper h3 {
    font-size: 20px;
  }
  .about__area-right {
    text-align: center;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    font-size: 20px;
  }
  .footer-list h6 {
    margin-bottom: 10px;
  }
  .services-icons .icon-box img {
    width: 70%;
    margin-left: 22px;
    margin-top: 4px;
  }
  .services-icons .icons-col {
    min-height: auto;
    margin-top: 14px;
  }
  .footer-logo {
    /* margin-top: 30px;
   */
    margin-top: 0px;
  }
  .about__area-right-list h5 {
    /* margin-left: 82px; */
    margin-left: 20px;
  }
  .slick-list {
    padding: 0 12px !important;
  }
  .ach_section_wrapper h3 {
    font-size: 22px;
  }
  .all-title {
    font-size: 22px;
    margin-top: 9px;
    padding: 10px;
  }
}
@media screen and (max-width: 440px) {
  .about-us-body,
  .appservices-body {
    margin-bottom: 72px;
  }
  .career-section .aboutus-text h1 {
    margin-bottom: -8px !important;
  }
  section.about-us-section.portfolio-section .about-us-body h1 {
    margin-top: 29px;
  }
}

/* --------- new additional css start 27-02-2023 -------- */

@media only screen and (max-width: 428px) {
  .ost-hero_banner_text h1 {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 300;
  }

  .portfolio_section img {
    height: 280px !important;
  }
  .ach_section_wrapper h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 370px) {
  .portfolio_section img {
    height: 250px !important;
  }
}

@media only screen and (max-width: 320px) {
  .ost-hero_banner_text h1 {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 300;
  }
  .portfolio-section {
    margin-top: 0px;
    background-size: cover;
    /* height: 300px; */
  }
}

@media only screen and (max-width: 428px) {
  .banner_btn_section {
    margin-top: 0px;
    text-align: center;
  }
}
@media only screen and (max-width: 320px) {
  .banner_btn_section {
    margin-top: 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 428px) {
  .btn_started {
    padding: 8px 32px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 320px) {
  .btn_started {
    padding: 8px 20px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 428px) {
  .btn_find_services {
    padding: 8px 20px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 320px) {
  .btn_find_services {
    padding: 8px 20px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 919px) {
  .img-responsive {
    width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 320px) {
  .img-responsive {
    width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 428px) {
  .img-responsive {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .img-responsive {
    width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 375px) {
  .img-responsive {
    width: 100%;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 919px) {
  .header_bg {
    background-size: cover;
  }
}

@media only screen and (max-width: 768px) {
  .header_bg {
    background-size: cover;
  }
}

@media only screen and (max-width: 428px) {
  .header_bg {
    background-size: cover;
  }
}
@media only screen and (max-width: 320px) {
  .header_bg {
    background-size: cover;
  }
}

@media only screen and (max-width: 428px) {
  .about__area-left-image-content {
    max-width: 230px;
    padding: 2px 15px;
    bottom: -40px;
  }
  .about__area-left-image-content h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 320px) {
  .about__area-left-image-content {
    max-width: 230px;
    padding: 2px 15px;
    bottom: -40px;
  }
  .about__area-left-image-content h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 428px) {
  .subtitle-two {
    font-size: 120px;
    margin-top: -10px;
  }
  .subtitle-one,
  .ach_section_wrapper h3,
  .testimonial-section .all-title,
  .industrial-body .all-title,
  .our-clients-body h2,
  .trending-technology .why-choose-us-title h2 {
    font-size: 25px;
  }

  .about__area-right-list {
    margin-top: 0px;
    margin: -8px;
  }
}
@media only screen and (max-width: 320px) {
  .subtitle-two {
    font-size: 120px;
    margin-top: -10px;
  }

  .subtitle-one {
    font-size: 20px;
  }

  .about__area-right-list {
    margin-top: 0px;
    margin: -8px;
  }
}

@media only screen and (max-width: 428px) {
  .pt-100 {
    padding-top: 50px;
  }
  .pt-5 {
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 320px) {
  .pt-100 {
    padding-top: 50px;
  }

  .pt-5 {
    padding-top: 1rem;
  }
}

@media only screen and (max-width: 428px) {
  .contact_card {
    padding: 10px;
    margin-bottom: 50px;
    margin-top: -30px;
  }
  .owlcarousel-slider .owl-nav {
    right: 0% !important;
    margin-top: -30px;
    margin-right: 100px;
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .contact_card {
    padding: 10px;
    margin-bottom: 50px;
    margin-top: -30px;
  }
}

@media only screen and (max-width: 428px) {
  .footer-logo {
    padding-bottom: 22px;
    /* margin-top: -62px; */
  }
}

@media only screen and (max-width: 320px) {
  .footer-logo {
    padding-bottom: 22px;
    /* margin-top: -62px; */
  }
}

@media only screen and (max-width: 919px) {
  .testimonial-section .thumb-layer .image img {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .testimonial-section .thumb-layer .image img {
    display: none;
  }
}
@media only screen and (max-width: 428px) {
  .testimonial-section .thumb-layer .image img {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .testimonial-section .thumb-layer .image img {
    display: none;
  }
}

/* @media only screen and (max-width: 428px) {
  .owlcarousel-slider .owl-nav {
    right: 0% !important;
    margin-top: 250px;
    margin-right: 100px;
  }
} */

@media only screen and (max-width: 320px) {
  .owlcarousel-slider .owl-nav {
    right: 0% !important;
    margin-top: 250px;
    margin-right: 100px;
  }
  .core-web-vital-section .col-body li h5 {
    font-size: 18px;
  }
  .core-web-vital-section .col-body li {
    width: 260px;
  }
}
.align-items-center {
  align-items: center !important;
  overflow: hidden;
}

.industrial-work {
  padding: 50px 0;
  overflow: hidden;
}