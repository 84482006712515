.portfolio_section {
  text-align: center;
  font-family: "Muli", sans-serif;
  /* background: #fff0fcbd; */
  overflow-x: hidden;
}
.portfolio_url {
  display: flex;
  width: 65%;
}
.portfolio_url a {
  margin-right: 10px;
  text-decoration: none;
  color: #1b2f34;
  font-weight: 500;
}
.portfolio_url a img {
  margin-top: 5px;
  width: 100%;
}
.portfolio_section:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -2;
  bottom: -120px;
  left: -120px;
  width: 400px;
  height: 400px;
  background: #a8b4fc30;
  animation: transform 50s ease-in-out infinite both alternate,
    movement 40s ease-in-out infinite both;
}

.portfolio_section::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-thumb {
  background: #e3e7ff;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #ffe6eb;
}

.portfolio_section form {
  display: flex;
  justify-content: center;
  margin: 30px 0 40px 0;
}

.portfolio_section input {
  display: none;
}

.portfolio_section label {
  background-color: #ec2229;
  color: #fff;
  font-weight: 500;
  padding: 12px 28px;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
  font-size: 19px;
}

.portfolio_section input:checked ~ label {
  /* background: #a8b4fc; */
  background: #f0ab11;
  padding: 11px 46px;
  border-radius: 5px;
  box-shadow: 5px 2px 15px #a8b4fc80;
}

.portfolio_section ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.portfolio_section li {
  margin: 10px 10px;
}

.portfolio_section figure {
  z-index: 1;
  /* min-width: 360px; */
  overflow: hidden;
  margin: 0 5px;
  animation: show 0.8s ease;
  box-shadow: 10px 0px 16px 0px rgb(108 108 108 / 18%);
  margin-bottom: 25px;
  border-radius: 0px 0px 60px 0px;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.portfolio_section img {
  /* width: 360px; */
  height: 320px;
  left: 0;
  object-fit: cover;
  /* border-radius: 20px; */
  box-shadow: 0 10px 15px #a8b4fc30;
  cursor: pointer;
}

figcaption {
  font-size: 24px;
  margin-top: 20px;
}

figure figcaption {
  margin: 0;
  position: relative;
  /* z-index: -1; */
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

/* figure:hover figcaption {
  opacity: 1;
  transform: translateY(-19px);
} */

figcaption {
  /* opacity: 1; */
  /* transform: translateY(-200px); */
  background: white;
  /* border-radius: 0 0 20px 20px; */
  padding: 10px;
  /* transition: 0.3s; */
}

.portfolio_section span {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #969696;
}

/* #root a{
    color: #faa7b7;
  } */

.portfolio_section .photo-source {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #969696;
  margin-bottom: 40px;
}

@keyframes transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes movement {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: translateY(20%) rotateY(10deg);
  }
}

.portfolio_section .fa-instagram {
  position: fixed;
  color: #cecece;
  top: 2%;
  right: 2%;
  font-size: 38px;
  font-weight: 400;
}
.portfolio_section .fa-instagram:hover {
  font-size: 42px;
  color: #faa7b7;
  transition: all 0.1s linear;
  cursor: pointer;
}

.contact_v1 {
  padding-bottom: 30px;
  z-index: 1;
}
.deneb_contact {
  position: relative;
}

.section_title h2 {
  margin-bottom: 30px;
  font-size: 22px;
}
.deneb_contact .contact_form .form_group {
  margin-bottom: 30px;
}
.deneb_contact .contact_form .form_group .form_control {
  border-radius: 4px;
  width: 100%;
  height: 45px;
  padding-left: 20px;
  /* box-shadow: 2.5px 4.33px 15px #00000026; */
  display: inherit;
  border: none;
  outline: none;
  resize: none;
  background-color: #ebebeb;
}

.deneb_contact .contact_form .form_group textarea {
  padding-top: 20px;
  min-height: 145px;
}

.deneb_btn {
  display: inline-block;
  min-width: 127px;
  height: 45px;
  color: #fff;
  font: 500 15px Poppins, sans-serif;
  text-align: center;
  background: #e34115;
  line-height: 45px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.5s;
  border: none;
  outline: none !important;
}
.deneb_contact .contact_form .button_box .deneb_btn {
  min-width: 170px;
}

/*portfolio slider */
.list-style-2 {
  padding-left: 0;
}
.list-style-2 > li {
  border-bottom: 1px solid #eaeaea;
  list-style-type: none;
  padding-bottom: 12px;
  padding-top: 12px;
}

.modal-dialog.modal-lg {
  width: 100% !important;
}
.modal {
  top: 10% !important;
}
.modal-header {
  display: block;
  border: none !important;
}
.modal-header p {
  margin-bottom: 0;
  text-align: center;
  font-weight: 600 !important;
  color: #000;
  font-size: 21px;
}
.modal-header .btn-close {
  float: right;
  margin-top: -35px;
}

.slick-dots {
  bottom: -4px !important;
}
.slick-dots li.slick-active button::before {
  border-color: #f5df4e !important;
  border: 1px solid #f5df4e !important;
  transition: all 0.5s ease !important;
  border-radius: 50%;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #f5df4e;
  font-size: 10px;
}
.slick-dots li button:before {
  font-size: 9px;
}
.slick-dots li button {
  -webkit-backface-visibility: visible;
  border: 1px solid transparent !important;
  border-radius: 30px !important;
  display: block !important;
  height: 20px !important;
  margin: 5px 3px !important;
  position: relative !important;
  transition: all 0.2s ease !important;
  width: 20px !important;
  background: transparent;
  border: 0 !important;
  color: transparent !important;
  cursor: pointer !important;
  line-height: 0 !important;
  outline: none !important;
  padding: 5px !important;
}
.slick-dots li {
  height: auto !important;
  margin: 0 !important;
  width: auto !important;
}
.slick-prev {
  left: 25px;
  z-index: 9999;
}
.slick-next {
  right: 25px;
  z-index: 9999;
}
.slick-next:before,
.slick-prev:before {
  color: #ff9189;
  font-size: 40px;
  background-color: transparent;
  border-radius: 50%;
  /* box-shadow: 0 2px 5px 0 rgba(0,0,0,.15); */
  /* color: hsla(0, 0%, 98%, 0.8); */
  height: 0 !important;
  margin: 0;
  /* opacity: 0; */
  text-align: center;
  top: calc(50% - 44px);
  transition: all 0.3s ease;
  width: 0 !important;
  z-index: 1;
}
.form_section_wrapper {
  background: #fff;
  padding: 40px;
  box-shadow: 0px 9px 16px 0px #c7414130;
}
.portfolio_title {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  font-family: sans-serif;
}

@media screen and (max-width: 767px) {
  .our-clients-section .sponser-img {
    padding: 10px 0;
    margin: 0;
    margin-left: 20px;
  }
  .portfolio_section label {
    font-size: 12px;
    padding: 12px 12px;
  }
  .portfolio_section input:checked ~ label {
    padding: 11px 20px;
  }
  .portfolio_section form {
    margin: 5px 0 0px 0;
  }
  .portfolio_section:after {
    background: none;
  }
  .modal {
    top: 7% !important;
  }
}
@media screen and (max-width: 390px) {
  .slick-next {
    right: 21px;
  }
  .our-clients-section .sponser-img {
    padding: 10px 0;
  }
}
@media screen and (max-width: 360px) {
  .portfolio_section input:checked ~ label {
    padding: 11px 10px;
  }
}
