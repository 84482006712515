.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.styles-module_whatsappButton__tVits {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 2rem;
  /* right: 2rem; */
  left: 2rem;
  cursor: pointer;
  background-color: #25d366;
  border-radius: 50%;
  -webkit-user-select: none;
  user-select: none;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 15%);
  z-index: 9998;
}

.styles-module_whatsappChatBox__MdQ5U {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5%;
  overflow: hidden;
  background-color: #fff;
  touch-action: auto;
  position: fixed;
  bottom: 7rem;
  /* right: 4rem; */
  left: 4rem;
  width: 375px;
  opacity: 0;
  transition: height 0.2s ease-out;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%);
  z-index: 9999;
}
.floating-button {
  position: fixed;
  left: 20px;
  bottom: 20px;
  padding: 15px 25px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  cursor: pointer;
  z-index: 1000; /* Ensures it stays on top */
}

.floating-button:hover {
  background-color: #0056b3;
}
